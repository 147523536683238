export const notifierMessagesState = {
  namespaced: true,
  state: {
    messages: []
  },
  mutations: {
    insertMessage(state, response) {
      if (response.response?.data ?? response?.data ?? response) {
        setTimeout(function tick() {
          state.messages = [];
        }, 5000);
        state.messages = {
          "message": response.response?.data ?? response?.data ?? response,
          "errors": response?.data?.errors
        }
      }
    },
  }
}
