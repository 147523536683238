export default class ProductImage {
  constructor(
    id ,
    title = '',
    description = '',
    created = '',
    updated = '',
    original_title = '',
    bucket = '',
    link = '',
    type = '',
    product = null,
    alt = ''

  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.created = created;
    this.updated = updated;
    this.original_title= original_title;
    this.bucket = bucket;
    this.link = link;
    this.type = type;
    this.product = product;
    this.alt = alt;
  }
}
