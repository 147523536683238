<template>
  <Header :company="company"/>
  <router-view/>
  <Footer :company="company"/>
  <Cookie/>
  <Notifier/>
</template>

<script>
import HeaderPage from "@/components/Header";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Cookie from "@/components/Cookie";
import Notifier from "@/components/Notifier";
import CompanyDataPage from "@/models/company_data_page";
import ProductImage from "@/models/productImage";
export default {
  components: {Notifier, Cookie, Footer, Header, HeaderPage},
  mounted() {
    this.getPage();
  },
  data() {
    return {
      company: new CompanyDataPage(),
    };
  },
  methods: {
    getPage() {
      let query = {
        itemsPerPage: 1,
        page: 1,
      };
      this.$store.dispatch('api/get_company_data', query).then(
        response => {
          this.totalRecordsLength = response['hydra:totalItems'];
          this.pages = response['hydra:member'].map(page => {
            return new CompanyDataPage(
              page.id,
              page?.name ?? '',
              page?.fullName ?? '',
              page?.phone ?? '',
              page?.email ?? '',
              page?.address ?? '',
              page?.content ?? '',
              new ProductImage(
                page?.logo?.id ?? null,
                page?.logo?.title ?? '',
                page?.logo?.description ?? '',
                page?.logo?.created ?? '',
                page?.logo?.updated ?? '',
                page?.logo?.original_title ?? '',
                page?.logo?.bucket ?? '',
                page?.logo?.link ?? '',
                page?.logo?.type ?? '',
                page?.logo?.product ?? null,
                page?.logo?.alt ?? '',
              ),
              page?.legalAddress ?? '',
              page?.inn ?? '',
              page?.kpp ?? '',
              page?.ogrn ?? '',
              page?.ogrnip ?? '',
              page?.acc ?? '',
              page?.corrAcc ?? '',
              page?.bank ?? '',
              page?.bic ?? '',
              page?.okved ?? '',
              page?.taxRegime ?? '',
              page?.linkMap ?? '',
            )});
          if (this.totalRecordsLength > 0) {
            this.files = null;
            this.company = this.pages[0];

          }
        },
        error => {
          // console.log(error.response);
        }
      );
    },
  }
}
</script>

<style lang="scss">
  //:root {
  //  --main-dark-color: #1b3542;
  //  --main-action-color: #6ac2ce;
  //  --main-background-color: #fefefe;
  //  --main-dark-text-color: #212529;
  //  --main-light-text-color: #ffffff;
  //  --main-body-text-color: #4b5563
  //}
  .position-sticky {
    z-index: 99;
  }
  //CSS template Vue.js
  li.p-carousel-indicator{
    button.p-link {
      background: var(--main-action-color);
    }
  }

  img.sales-img {
    height: 288px;
    width: auto;
  }

  .cover {
    background-size: cover!important;
  }

  .background-is-dark {
    background: var(--main-dark-color);
  }

  .p-focus {
    box-shadow: 0 0 0 0.2rem var(--main-action-color);
  }
  .form-control:enabled:focus,
  .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem var(--main-action-color);
    border-color: var(--main-action-color);
  }

  .p-button {
    border: 1px solid var(--main-action-color);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
    outline-color: transparent;
  }
  span.p-button-icon.pi {
    color: var(--main-action-color);
  }
  .p-component:hover, input:hover, textarea:hover {
    border-color: var(--main-action-color);
  }

  table tbody button.p-button.p-component.p-button-icon-only.p-button-text {
    padding: 0.75rem 0.75rem;
  }

  button {
    &.p-galleria-thumbnail-prev {
      color: var(--main-dark-color);
    }
    &.p-galleria-thumbnail-next {
      color: var(--main-dark-color);
    }
  }
  .p-galleria-indicator {
    button {
      background: var(--main-action-color);
    }
  }
  .p-galleria .p-galleria-thumbnail-container {
    background: var(--main-light-text-color);
    padding: 1rem 0.25rem;
  }

  .p-checkbox.p-highlight .p-checkbox-box {
    background-color: var(--main-action-color);
    border-color: var(--main-light-text-color);
  }

  .p-checkbox-box {
    border-color: var(--main-action-color);
  }

  button.btn-outline-light-style {
    border-color: var(--main-action-color);
    .pi:before {
      color: var(--main-action-color);
    }
    span {
      color: var(--main-action-color);
    }
    &:hover{
      .pi:before {
        color: var(--main-light-text-color);
      }
      background-color: var(--main-action-color);
      border-color: var(--main-action-color);
      span {
        color: var(--main-light-text-color);
      }
    }
  }

  button.btn-outline-active-style {
    border-color: var(--main-action-color);
    background-color: var(--main-action-color);
    .pi:before {
      color: var(--main-light-text-color);
    }
    span {
      color: var(--main-light-text-color);
    }
    &:hover{
      .pi:before {
        color: var(--main-action-color);
      }
      border-color: var(--main-action-color);
      span {
        color: var(--main-action-color);
      }
    }
  }

  .pi.pi-star-fill {
    color: var(--main-action-color);
  }

  .p-slider .p-slider-range {
    background: var(--main-action-color);
    border-radius: 6px;
  }

  .p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: var(--main-light-text-color);
    border: 2px solid var(--main-action-color);
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    outline-color: transparent;
  }

  .p-rating-item.p-rating-item-active {
    svg {
      color: var(--main-action-color);
    }
  }

  .p-progressbar .p-progressbar-value {
    background: var(--main-action-color);
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: var(--main-light-text-color);
    border-color: var(--main-action-color);
    color: var(--main-action-color);
  }

  //Custom css style
  body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--main-body-text-color);
    text-align: var(--bs-body-text-align);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  .nav-start{
    margin-left: 1.2rem;
  }
  .nav-end {
    margin-left: 1.2rem;
  }

  .flex {
    display: flex;
  }
  p.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    white-space: nowrap;
    margin: 0px;
  }

  p.product-card-header-cut-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .form-check-input:checked {
    background-color: var(--main-action-color)!important;
    border-color: var(--main-dark-color)!important;
  }
  .cart {
    color: var(--main-action-color);
  }
  .p-datatable .p-datatable-thead > tr > th {
    font-weight: 400;
  }
  .card-without-border {
    border: 0px!important;
    & a {
      color: var(--main-action-color);
    }
    & img {
      background: #f9fafb;
    }
  }

  .p-datatable {
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-width: 0px 0px 0px 0px;
  }

  .p-datatable-header {
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-width: 1px 1px 0px 1px;
  }

  thead tr th {
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-width: 1px 1px 1px 1px;
  }

  tbody tr {
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-width: 1px 1px 1px 1px;
  }

  .p-paginator.p-component {
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-width: 0px 1px 1px 1px;
    border-radius: 0px 0px 4px 4px;
  }

  .pi.pi-search {
    color: inherit;
    font-size: large;
  }

  ul.p-tree-container {
    padding: 0px;
    margin: 0px;
  }
  li.p-treenode {
    display: block;
  }
  .header {
    min-height: 98px;
  }
  a {
    color: var(--main-action-color)!important;
  }

  .p-galleria-thumbnail-item-content img {
    height: 96px;
    width: 96px;
  }

  table img {
    height: 96px;
    width: 96px;
  }

  .p-dropdown-items-wrapper ul {
    padding-left: 0rem;
  }

  textarea.form-control.w-100 {
    min-height: 380px;
  }

  label.form-label {
    color: initial;
  }

  span.p-tag-value {
    color: var(--main-light-text-color);
  }
  i.pi.pi-times-circle.text-red-400 {
    color: red;
  }
  i.pi.pi-check-circle.text-green-500 {
    color: var(--main-action-color);
  }

  input.p-inputtext.p-component {
    max-height: fit-content;
  }

  button.p-button-danger {
    border-color: var(--main-action-color);
  }

  div.file-uploaded-list{
    & ul {
      padding-left: 0px;
      & li {
        height: 4.4rem;
        margin: 0;
      }
    }
    & img.product-image-item {
      display: block;
      height: 100%;
      width: auto;
      border-radius: 2.4rem;
    }
  }
  ul li {
    list-style-type: none;
  }

  .card-title {
    height: 3.2rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap;
  }

  .card-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap;
  }

  p.card-text {
    line-height: 1.6rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    max-height: 6.2rem;
    min-height: 6.2rem;
  }

  .img-title {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  img.map {
    border-radius: 8px;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    img.hide-mobile {
      display: none;
    }
  }
</style>
