<template>
  <ProgressBar v-show="loading" mode="indeterminate" style="height: 6px"></ProgressBar>
</template>

<script>
import ProgressBar from 'primevue/progressbar';

export default {
  name: 'Progress',
  components: {
    ProgressBar
  },
  computed: {
    loading () {
      return this.$store.state.loadingState.loading;
    }
  }
}
</script>
