<template>
  <ProductCatalogsBlock />
</template>

<script>
import ProductCatalogsBlock from "@/components/block-items/ProductCatalogsBlock.vue";

export default {
  name: 'ProductCatalogs',
  components: {
    ProductCatalogsBlock,
  }
}
</script>
