import Products from "@/models/products";

export default class ProductRatings {
  constructor(
    id ,
    estimation = 0,
    product_name = ''
  ) {
    this.id = id;
    this.estimation = estimation;
    this.product_name = product_name
  }
}
