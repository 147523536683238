<template>
  <transition name="slide-fade">
    <div v-if="show" class="text-center">
      <div id="cookie_notification">
        <p>Для улучшения работы сайта и его взаимодействия с пользователями мы используем файлы cookie. Продолжая работу с сайтом, Вы разрешаете использование cookie-файлов. Вы всегда можете отключить файлы cookie в настройках Вашего браузера.</p>
        <button class="btn btn-outline-active-style" @click="clickApprove()"><span>Принять</span></button>
      </div>
    </div>
  </transition>
</template>

<script>

  export default {
    name: 'Cookie',
    components: {
    },
    data() {
      return {
        show: false
      }
    },
    mounted() {
      this.checkCookies();
    },
    methods: {
      checkCookies(){
        let cookieDate = localStorage.getItem('cookieDate');
        // Если записи про кукисы нет или она просрочена на 1 год, то показываем информацию про кукисы
        if( !cookieDate || (+cookieDate + 31536000000) < Date.now() ){
            this.show = true;
        }
      },
      clickApprove(){
        localStorage.setItem( 'cookieDate', Date.now());
        this.show = false;
      }
    }
  }
</script>


<style>
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
  div#cookie_notification {
      z-index: 9999;
      position: fixed;
      bottom: 0px;
      padding: 18px;
      background: rgb(236, 239, 241) !important;
      color: rgb(52, 58, 64);
  }
</style>
