import axios from 'axios';
import authHeader from './auth-header';
import authHeaderPatch from "./auth-header";

const API_URL = 'https://api.enzhtech.ru/api/';

class ApiService {
  change_password(user) {
    const formData = new FormData();
    formData.append('id', user.id);
    formData.append('password', user.password);
    formData.append('new_password', user.new_password);
    return axios.post(API_URL + 'change_pasword/user', formData, { headers: authHeader() });
  };
  update_user(user) {
    const formData = new FormData();
    formData.append('id', user.id);
    formData.append('email', user.email);
    formData.append('password', user.password);
    formData.append('verified', user.verified);
    formData.append('blocked', user.blocked);
    formData.append('agree', user.agree);
    formData.append('roles', user.roles);
    return axios.post(API_URL + 'update/user', formData, { headers: authHeader() });
  };
  suggest_product(data) {
    let dataRequest = {
      "query": data.query,
      "category": data.category,
      "type": data.type,
    };
    return axios.post(API_URL + 'suggest_product', JSON.stringify(dataRequest), { headers: authHeader() });
  };
  search_product(data) {
    let dataRequest = {
      "query": data.query,
      "category": data.category,
      "type": data.type,
    };
    return axios.post(API_URL + 'search_product', JSON.stringify(dataRequest), { headers: authHeader() });
  };
  get_client() {
    return axios.get(API_URL + 'get_user_by_email', {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  get_clients(query) {
    return axios.get(API_URL + 'users', {
      headers: authHeader(),
      params: query
    });
  };
  delete_client(data) {
    return axios.delete(API_URL + 'users/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_client(data) {
    if(!data.id) {
      return axios.post(API_URL + 'users', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'users/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_product_price(data) {
    return axios.post(API_URL + 'product_price', JSON.stringify(data) , {
      headers: authHeader(),
    });
  };
  get_product(data) {
    return axios.get(API_URL + 'products/' + data.id, {
      headers: authHeader()
    });
  };
  get_products(query) {
    return axios.get(API_URL + 'products', {
      headers: authHeader(),
      params: query
    });
  };
  delete_product(data) {
    return axios.delete(API_URL + 'products/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_product(data) {
    if(!data.id) {
      return axios.post(API_URL + 'products', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'products/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_images(query) {
    return axios.get(API_URL + 'product_images', {
      headers: authHeader(),
      params: query
    });
  };
  delete_image(data) {
    return axios.delete(API_URL + 'product_images/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_image(data) {
    const formData = new FormData();
    data.files.forEach(function (file, index) {
      formData.append(file.name, file);
    });
    return axios.post(API_URL + 'product_images', formData , {
      headers: authHeader(),
      params: {
        id: data?.image?.id ?? null,
        alt: data?.image?.alt ?? null,
        description: data?.image?.description ?? null,
        productId: data?.productId ?? data?.image?.product?.id ?? null
      }
    });
  };
  get_product_category(query) {
    return axios.get(API_URL + 'product_categories', {
      headers: authHeader(),
      params: query
    });
  };
  delete_product_category(data) {
    return axios.delete(API_URL + 'product_categories/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_product_category(data) {
    if(!data.id) {
      return axios.post(API_URL + 'product_categories', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'product_categories/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_product_type(query) {
    return axios.get(API_URL + 'product_types', {
      headers: authHeader(),
      params: query
    });
  };
  delete_product_type(data) {
    return axios.delete(API_URL + 'product_types/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_product_type(data) {
    const formData = new FormData();
    data.files.forEach(function (file, index) {
      formData.append(file.name, file);
    });
    return axios.post(API_URL + 'product_types', formData , {
      headers: authHeader(),
      params: {
        id: data.type?.id ?? null,
        value: data.type.value,
        description: data.type.description
      }
    });
  };
  get_product_rating(query) {
    return axios.get(API_URL + 'product_ratings', {
      headers: authHeader(),
      params: query
    });
  };
  delete_product_rating(data) {
    return axios.delete(API_URL + 'product_ratings/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_product_rating(data) {
    if(!data.id) {
      return axios.post(API_URL + 'product_ratings', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'product_ratings/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };


  get_product_property_value(query) {
    return axios.get(API_URL + 'product_property_values', {
      headers: authHeader(),
      params: query
    });
  };
  delete_product_property_value(data) {
    return axios.delete(API_URL + 'product_property_values/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_product_property_value(data) {
    if(!data.id) {
      return axios.post(API_URL + 'product_property_values', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'product_property_values/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_product_property(query) {
    return axios.get(API_URL + 'product_properties', {
      headers: authHeader(),
      params: query
    });
  };
  delete_product_property(data) {
    return axios.delete(API_URL + 'product_properties/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_product_property(data) {
    if(!data.id) {
      return axios.post(API_URL + 'product_properties', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'product_properties/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_product_context(data) {
    return axios.get(API_URL + 'product_contexts/' + data.id, {
      headers: authHeader(),
    });
  };
  edit_product_context(data) {
    if(!data.id) {
      return axios.post(API_URL + 'product_contexts', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'product_contexts/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_category_context(query) {
    return axios.get(API_URL + 'categories_contexts', {
      headers: authHeader(),
      params: query
    });
  };
  edit_category_context(data) {
    if(!data.id) {
      return axios.post(API_URL + 'categories_contexts', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'categories_contexts/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  send_feedback_message(data) {
    return axios.post(API_URL + 'send_feedback_message', JSON.stringify(data) , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  get_feedback_messages(query) {
    return axios.get(API_URL + 'feedback_messages', {
      headers: authHeader(),
      params: query
    });
  };
  get_block_list(data) {
    return axios.get(API_URL + 'block_lists/' + data.id, {
      headers: authHeader()
    });
  };
  get_block_lists(query) {
    return axios.get(API_URL + 'block_lists', {
      headers: authHeader(),
      params: query
    });
  };
  delete_block_list(data) {
    return axios.delete(API_URL + 'block_lists/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_block_list(data) {
    if(!data.id) {
      return axios.post(API_URL + 'block_lists', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'block_lists/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_sales_promoution_page(data) {
    return axios.get(API_URL + 'sales_promoution_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_sales_promoution_pages(query) {
    return axios.get(API_URL + 'sales_promoution_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_sales_promoution_page(data) {
    return axios.delete(API_URL + 'sales_promoution_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_sales_promoution_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'sales_promoution_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'sales_promoution_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_product_catalog_page(data) {
    return axios.get(API_URL + 'product_catalog_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_product_catalog_pages(query) {
    return axios.get(API_URL + 'product_catalog_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_product_catalog_page(data) {
    return axios.delete(API_URL + 'product_catalog_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_product_catalog_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'product_catalog_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'product_catalog_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_service_catalog_page(data) {
    return axios.get(API_URL + 'service_catalog_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_service_catalog_pages(query) {
    return axios.get(API_URL + 'service_catalog_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_service_catalog_page(data) {
    return axios.delete(API_URL + 'service_catalog_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_service_catalog_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'service_catalog_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'service_catalog_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_hot_offer_page(data) {
    return axios.get(API_URL + 'hot_offer_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_hot_offer_pages(query) {
    return axios.get(API_URL + 'hot_offer_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_hot_offer_page(data) {
    return axios.delete(API_URL + 'hot_offer_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_hot_offer_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'hot_offer_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'hot_offer_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_new_offer_page(data) {
    return axios.get(API_URL + 'new_offer_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_new_offer_pages(query) {
    return axios.get(API_URL + 'new_offer_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_new_offer_page(data) {
    return axios.delete(API_URL + 'new_offer_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_new_offer_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'new_offer_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'new_offer_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_custom_page(data) {
    return axios.get(API_URL + 'custom_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_custom_pages(query) {
    return axios.get(API_URL + 'custom_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_custom_page(data) {
    return axios.delete(API_URL + 'custom_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_custom_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'custom_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'custom_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_call_to_action_page(data) {
    return axios.get(API_URL + 'call_to_action_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_call_to_action_pages(query) {
    return axios.get(API_URL + 'call_to_action_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_call_to_action_page(data) {
    return axios.delete(API_URL + 'call_to_action_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_call_to_action_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'call_to_action_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'call_to_action_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };
  get_stages_page(data) {
    return axios.get(API_URL + 'stages_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_stages_pages(query) {
    return axios.get(API_URL + 'stages_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_stages_page(data) {
    return axios.delete(API_URL + 'stages_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_stages_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'stages_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'stages_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };

  get_facts_page(data) {
    return axios.get(API_URL + 'facts_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_facts_pages(query) {
    return axios.get(API_URL + 'facts_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_facts_page(data) {
    return axios.delete(API_URL + 'facts_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_facts_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'facts_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'facts_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };

  get_questions_and_answers_page(data) {
    return axios.get(API_URL + 'questions_and_answers_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_questions_and_answers_pages(query) {
    return axios.get(API_URL + 'questions_and_answers_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_questions_and_answers_page(data) {
    return axios.delete(API_URL + 'questions_and_answers_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_questions_and_answers_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'questions_and_answers_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'questions_and_answers_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };

  get_reviews_page(data) {
    return axios.get(API_URL + 'reviews_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_reviews_pages(query) {
    return axios.get(API_URL + 'reviews_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_reviews_page(data) {
    return axios.delete(API_URL + 'reviews_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_reviews_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'reviews_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'reviews_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };

  get_partners_page(data) {
    return axios.get(API_URL + 'partners_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_partners_pages(query) {
    return axios.get(API_URL + 'partners_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_partners_page(data) {
    return axios.delete(API_URL + 'partners_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_partners_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'partners_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'partners_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };

  get_advantages_page(data) {
    return axios.get(API_URL + 'advantages_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_advantages_pages(query) {
    return axios.get(API_URL + 'advantages_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_advantages_page(data) {
    return axios.delete(API_URL + 'advantages_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_advantages_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'advantages_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'advantages_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };

  get_portfolio_page(data) {
    return axios.get(API_URL + 'portfolio_pages/' + data.id, {
      headers: authHeader()
    });
  };
  get_portfolio_pages(query) {
    return axios.get(API_URL + 'portfolio_pages', {
      headers: authHeader(),
      params: query
    });
  };
  delete_portfolio_page(data) {
    return axios.delete(API_URL + 'portfolio_pages/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_portfolio_page(data) {
    if(!data.id) {
      return axios.post(API_URL + 'portfolio_pages', JSON.stringify(data) , {
        headers: authHeader(),
        // params: {
        //   id: data
        // }
      });
    }
    return axios.patch(API_URL + 'portfolio_pages/' + data.id, JSON.stringify(data) , {
      headers: authHeaderPatch('PATCH'),
      // params: {
      //   id: data
      // }
    });
  };

  get_page_item(data) {
    return axios.get(API_URL + 'page_items/' + data.id, {
      headers: authHeader()
    });
  };
  get_page_items(query) {
    return axios.get(API_URL + 'page_items', {
      headers: authHeader(),
      params: query
    });
  };
  delete_page_item(data) {
    return axios.delete(API_URL + 'page-items/' + data , {
      headers: authHeader(),
      // params: {
      //   id: data
      // }
    });
  };
  edit_page_item(data) {
    const formData = new FormData();
    if(data.image?.length > 0){
      data.image?.forEach(function (file, index) {
        formData.append('image', file);
      });
    }
    if(data.icon?.length > 0){
      data.icon.forEach(function (file, index) {
        formData.append('icon', file);
      });
    }
    return axios.post(API_URL + 'page-items', formData, {
      headers: authHeader(),
      params: {
        id: data?.id ?? null,
        title: data.title,
        subtitle: data.subtitle,
        description: data.description,
        isDeleted: data.isDeleted,
        orderBy: data.orderBy,
        blockList: data.blockList?.id,
      }
    });
  };
  get_company_data(data) {
    return axios.get(API_URL + 'company_datas', {
      headers: authHeader(),
    });
  };
  edit_company_data(data) {
    const formData = new FormData();
    if(data.files?.length > 0){
      data.files?.forEach(function (file, index) {
        formData.append(file.name, file);
      });
    }
    if(data.company?.id){
      formData.append('id', data.company?.id);
    }
    formData.append('name', data.company?.name ?? '');
    formData.append('fullName', data.company?.fullName ?? '');
    formData.append('phone', data.company?.phone ?? '');
    formData.append('email', data.company?.email ?? '');
    formData.append('address', data.company?.address ?? '');
    formData.append('content', data.company?.content ?? '');
    formData.append('legalAddress',data.company?.legalAddress ?? '');
    formData.append('inn',data.company?.inn ?? '');
    formData.append('kpp',data.company?.kpp ?? '');
    formData.append('ogrn',data.company?.ogrn ?? '');
    formData.append('ogrnip',data.company?.ogrnip ?? '');
    formData.append('acc',data.company?.acc ?? '');
    formData.append('corrAcc',data.company?.corrAcc ?? '');
    formData.append('bank',data.company?.bank ?? '');
    formData.append('bic',data.company?.bic ?? '');
    formData.append('okved',data.company?.okved ?? '');
    formData.append('taxRegime',data.company?.taxRegime ?? '');
    formData.append('linkMap',data.company?.linkMap ?? '');
    return axios.post(API_URL + 'company_datas', formData , {
      headers: authHeader(),
      // params: {
      // }
    });
  };
}

export default new ApiService();
