<template>
  <div id="ServiceCatalogsBlock" class="container my-2">
    <div class="row row-cols-lg-12 row-cols-md-12 row-cols-sm-12 g-4 my-2">
      <div class="col-lg-12 col-mb-12 col-sm-12 text-left">
        <h2>Каталог услуг</h2>
      </div>
    </div>
    <div class="row row-cols-lg-12 row-cols-md-12 row-cols-sm-12 g-4 my-2">
      <div class="col-lg-4 col-mb-4 col-sm-12" v-for="product in products">
        <div class="card">
          <img :src="product.productImage.link" :alt="product.productImage.alt" class="card-img-top" />
          <div class="card-body">
            <p class="card-title">{{ product.name }}</p>
            <p class="card-description" v-html="product.description"></p>
            <div class="flex align-items-center justify-content-center gap-2 mt-4">
              <Button class="btn btn-outline-light-style" label="Подробнее" rounded @click="routeToProductBoard(product)" />
              <Button class="btn btn-outline-light-style ml-auto" label="Заказать" rounded @click="showServiceModal(product)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container my-4 text-center">
    <div class="row ">
      <div class="col-12">
        <button type="button" class="btn btn-light" @click="nextSlice()">Смотреть еще</button>
      </div>
    </div>
  </div>
  <ServiceDialogModal
    ref="serviceModalComponent"/>
</template>

<script>
import Rating from 'primevue/rating';
import Button from 'primevue/button';

import Products from "@/models/products";
import ProductRatings from "@/models/productRatings";
import FeedbackForm from "@/components/feedback-form/FeedbackForm.vue";
import ServiceDialogModal from "@/components/modal/ServiceDialogModal.vue";

export default {
  name: 'ServiceCatalogsBlock',
  components: {
    ServiceDialogModal,
    FeedbackForm,
    Rating,
    Button,
  },
  data() {
    return {
      products: [],
      page: 1,
    };
  },
  mounted() {
    this.getProductList();
  },
  methods: {
    showServiceModal(data) {
      this.$refs.serviceModalComponent.showModal(data);
    },
    routeToProductBoard(data) {
      this.$router.push('/product-view/' + data.id);
    },
    getProductList() {
      let query = {
        itemsPerPage: 3,
        page: this.page ? this.page : 1,
        isService: true,
        isDeleted: false,
      };
      this.$store.dispatch('api/get_products', query).then(
        response => {
          this.totalRecordsLength = response['hydra:totalItems'];
          this.products = response['hydra:member'].map(product => {
            return new Products(
              product.id ,
              product.name,
              product.description,
              product.created,
              product.updated,
              product.image.length === 0 ? product.image : product.image[0],
              product.productPropertyValue,
              product.productCategory,
              product.isDeleted,
              product.productType,
              new ProductRatings(
                product.productRating.id,
                product.productRating?.estimation ?? 0
              ),
              product.productContext,
            )});
        },
        error => {
          // console.log(error.response);
        }
      );
    },
    nextSlice() {
      this.page = this.page ? this.page + 1 : 1
      let query = {
        itemsPerPage: 3,
        page: this.page,
        isService: true,
        isDeleted: false,
      };
      this.$store.dispatch('api/get_products', query).then(
        response => {
          this.totalRecordsLength = response['hydra:totalItems'];
          response['hydra:member'].map(product => {
            this.products.push( new Products(
              product.id ,
              product.name,
              product.description,
              product.created,
              product.updated,
              product.image.length === 0 ? product.image : product.image[0],
              product.productPropertyValue,
              product.productCategory,
              product.isDeleted,
              product.productType,
              new ProductRatings(
                product.productRating.id,
                product.productRating?.estimation ?? 0
              ),
              product.productContext,
            ))});
        },
        error => {
          // console.log(error.response);
        }
      );
    }
  }

}
</script>


<style lang="scss">
#ServiceCatalogsBlock {
  & img {
    height: 280px;
    width: auto;
    margin: auto;
    background-size: contain;
    padding: 1rem 1rem;
  }
}
</style>
