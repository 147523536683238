import AuthService from '@/services/auth.service';
import router from '@/router'
import ApiService from "@/services/api.service";

const token = localStorage.getItem('user.token') ?? null;
const initialState = token
  ? { status: { loggedIn: true }, token}
  : { status: { loggedIn: false }, token: null};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      commit('loadingState/startLoading', null, { root: true });
      return AuthService.login(user).then(
        response => {
          commit('loginSuccess', {
            data: response.data,
            rememberMe: user.rememberMe
          });
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          commit('loginFailure');
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          commit('restoreUserData');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      commit('restoreUserData');
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      commit('loadingState/startLoading', null, { root: true });
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', response, { root: true });
          commit('restoreUserData');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          commit('restoreUserData');
          return Promise.reject(error);
        }
      );
    },
    verify_email({ commit }, params_data) {
      commit('loadingState/startLoading', null, { root: true });
      return AuthService.verifyEmail(params_data)
        .then(
          response => {
            commit('verifyEmail');
            commit('loadingState/stopLoading', null, { root: true });
            commit('restoreUserData');
            return Promise.resolve(response.data);
          },
          error => {
            commit('loadingState/stopLoading', null, { root: true });
            commit('notifierMessagesState/insertMessage', error, { root: true });
            commit('restoreUserData');
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          commit('restoreUserData');
          return Promise.reject(error);
        });
    },
    forget_password({ commit }, user) {
      commit('loadingState/startLoading', null, { root: true });
      return AuthService.forget_password(user).then(
        response => {
          commit('forgetPassword');
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', response, { root: true });
          commit('restoreUserData');
          return Promise.resolve(response.data);
        },
        error => {
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          commit('restoreUserData');
          return Promise.reject(error);
        }
      );
    },
    verify_reset_password({ commit }, params_data) {
      commit('loadingState/startLoading', null, { root: true });
      return AuthService.verifyResetPassword(params_data)
        .then(
          response => {
            commit('verifyResetPassword');
            commit('loadingState/stopLoading', null, { root: true });
            commit('notifierMessagesState/insertMessage', response, { root: true });
            commit('restoreUserData');
            router.push('/login');
          },
          error => {
            commit('loadingState/stopLoading', null, { root: true });
            commit('notifierMessagesState/insertMessage', error, { root: true });
            commit('restoreUserData');
          }
        )
    },
    refresh_token({ commit }) {
      commit('loadingState/startLoading', null, { root: true });
      return AuthService.refreshToken(localStorage.getItem('user.token'))
        .then(
          response => {
            commit('refreshSuccess', response.data);
            commit('loadingState/stopLoading', null, { root: true });
            commit('restoreUserData');
            if (localStorage.getItem('user.remember_me') === "true") {
              router.go(router.currentRoute);
            } else {
              AuthService.logout();
              commit('logout');
              commit('restoreUserData');
              router.push('/login');
            }
          },
          error => {
            commit('loadingState/stopLoading', null, { root: true });
            commit('notifierMessagesState/insertMessage', error, { root: true });
            AuthService.logout();
            commit('logout');
            commit('restoreUserData');
            router.push('/login');
          }
        )
    },
  },
  mutations: {
    loginSuccess(state, responseData) {
      state.status.loggedIn = true;
      state.token = responseData.data.token;
      localStorage.setItem('user.token', responseData.data.token);
      localStorage.setItem('user.refresh_token', responseData.data.refresh_token);
      localStorage.setItem('user.remember_me', responseData.rememberMe ?? false);
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.token = null;
    },
    logout(state) {
      state.remember_me = false
      state.status.loggedIn = false;
      state.token = null;
      localStorage.removeItem('user.token');
      localStorage.removeItem('user.refresh_token');
      localStorage.removeItem('user.remember_me');
      localStorage.removeItem('user.name');
      localStorage.removeItem('user.email');
      localStorage.removeItem('user.phone');
    },
    registerSuccess(state) {
      state.remember_me = false
      state.status.loggedIn = false;
      state.token = null;
      localStorage.removeItem('user.token');
      localStorage.removeItem('user.refresh_token');
      localStorage.removeItem('user.remember_me');
    },
    registerFailure(state) {
      state.remember_me = false
      state.status.loggedIn = false;
      state.token = null;
      localStorage.removeItem('user.token');
      localStorage.removeItem('user.refresh_token');
      localStorage.removeItem('user.remember_me');
    },
    verifyEmail(state) {
      state.remember_me = false
      state.status.loggedIn = false;
      state.token = null;
      localStorage.removeItem('user.token');
      localStorage.removeItem('user.refresh_token');
      localStorage.removeItem('user.remember_me');
    },
    forgetPassword(state) {
      state.remember_me = false
      state.status.loggedIn = false;
      state.token = null;
      localStorage.removeItem('user.token');
      localStorage.removeItem('user.refresh_token');
      localStorage.removeItem('user.remember_me');
    },
    verifyResetPassword(state) {
      state.remember_me = false
      state.status.loggedIn = false;
      state.token = null;
      localStorage.removeItem('user.token');
      localStorage.removeItem('user.refresh_token');
      localStorage.removeItem('user.remember_me');
    },
    refreshSuccess(state, data) {
      state.remember_me = false
      state.status.loggedIn = true;
      state.token = data.token;
      localStorage.setItem('user.token', data.token);
      localStorage.setItem('user.refresh_token', data.refresh_token);
    },
    restoreUserData(state) {
      state.isAdmin = false;
      localStorage.removeItem('user.isAdmin');
    }
  }
};
