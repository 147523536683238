<template>
  <div id="NewOffersBlock" class="container my-2">
    <div class="row row-cols-lg-12 row-cols-md-12 row-cols-sm-12 g-4 my-2">
      <div class="col-lg-12 col-mb-12 col-sm-12 text-left">
        <h2>Новинки</h2>
      </div>
    </div>
    <div class="row row-cols-lg-12 row-cols-md-12 row-cols-sm-12 g-4 my-2">
      <div class="col-lg-3 col-mb-3 col-sm-12" v-for="product in products">
        <div class="card">
          <img :src="product.productImage.link" alt="product.productImage.alt" class="card-img-top" />
          <div class="card-body">
            <p class="card-title">{{ product.name }}</p>
            <Rating
              class="my-2 mb-4"
              v-model="product.productRating.estimation"
              :cancel="false"
              readonly
            />
            <div class="flex align-items-center justify-content-center gap-2">
              <Button class="btn btn-outline-light-style" label="Подробнее" rounded @click="routeToProductBoard(product)" />
              <Button class="btn btn-outline-light-style ml-auto" label="Купить" rounded @click="routeToProductBoard(product)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container my-4 text-center">
    <div class="row ">
      <div class="col-12">
        <button type="button" class="btn btn-light" @click="nextSlice()">Смотреть еще</button>
      </div>
    </div>
  </div>
</template>

<script>
import Rating from 'primevue/rating';
import Button from 'primevue/button';

import Products from "@/models/products";
import ProductRatings from "@/models/productRatings";

export default {
  name: 'NewOffersBlock',
  components: {
    Rating,
    Button,
  },
  data() {
    return {
      products: [],
      page: 1,
    };
  },
  mounted() {
    this.getProductList();
  },
  methods: {
    routeToProductBoard(data) {
      this.$router.push('/product-view/' + data.id);
    },
    getProductList() {
      let query = {
        itemsPerPage: 4,
        page: this.page ? this.page : 1,
        isService: false,
        isDeleted: false,
      };
      this.$store.dispatch('api/get_products', query).then(
        response => {
          this.totalRecordsLength = response['hydra:totalItems'];
          this.products = response['hydra:member'].map(product => {
            return new Products(
              product.id ,
              product.name,
              product.description,
              product.created,
              product.updated,
              product.image.length === 0 ? product.image : product.image[0],
              product.productPropertyValue,
              product.productCategory,
              product.isDeleted,
              product.productType,
              new ProductRatings(
                product.productRating.id,
                product.productRating?.estimation ?? 0
              ),
              product.productContext,
            )});
        },
        error => {
          // console.log(error.response);
        }
      );
    },
    nextSlice() {
      this.page = this.page ? this.page + 1 : 1
      let query = {
        itemsPerPage: 4,
        page: this.page,
        isService: false,
        isDeleted: false,
      };
      this.$store.dispatch('api/get_products', query).then(
        response => {
          this.totalRecordsLength = response['hydra:totalItems'];
          response['hydra:member'].map(product => {
            this.products.push( new Products(
              product.id ,
              product.name,
              product.description,
              product.created,
              product.updated,
              product.image.length === 0 ? product.image : product.image[0],
              product.productPropertyValue,
              product.productCategory,
              product.isDeleted,
              product.productType,
              new ProductRatings(
                product.productRating.id,
                product.productRating?.estimation ?? 0
              ),
              product.productContext,
            ))});
        },
        error => {
          // console.log(error.response);
        }
      );
    }
  }

}
</script>


<style lang="scss">
#NewOffersBlock {
  & img {
    height: 280px;
    margin: auto;
    background-size: contain;
    padding: 1rem 1rem;
  }
}

.card-title {
  height: 3.2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
}

p.card-text {
  line-height: 1.6rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-height: 6.2rem;
  min-height: 6.2rem;
}
</style>
