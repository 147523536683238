import axios from 'axios';

const API_URL = 'https://api.enzhtech.ru/api/';

class AuthService {
  login(user) {
    let data = {
      "email": user.email,
      "password": user.password
    }
    return axios
      .post(API_URL + 'login_check', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  refreshToken() {
    const formData = new FormData();
    formData.append('refresh_token', localStorage.getItem('user.refresh_token'));
    return axios
      .post(API_URL + 'token/refresh', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  logout() {
    localStorage.removeItem('user.token');
    localStorage.removeItem('user.refersh_token');
  }

  register(user) {
    let data = {
      "email": user.email,
      "password": user.password,
      "isAgree": user.isAgree,
    };
    return axios.post(API_URL + 'register', JSON.stringify(data));
  }

  verifyEmail(params_data) {
    return axios.get(API_URL + 'verify/email', { params: params_data });
  }

  forget_password(user) {
    return axios.post(API_URL + 'forget_password', JSON.stringify(user));
  }

  verifyResetPassword(params_data) {
    const formData = new FormData();
    formData.append('expires', params_data.expires);
    formData.append('id', params_data.id);
    formData.append('signature', params_data.signature);
    formData.append('token', params_data.token);
    formData.append('password', params_data.password);
    formData.append('check_password', params_data.check_password);
    return axios.post(API_URL + 'verify/reset/password', formData);
  }
}

export default new AuthService();
