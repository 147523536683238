export default class Page {
  constructor(
    id ,
    name = '',
    description = '',
    created = null,
    updated = null,
    isDeleted = false,
    orderBy = null,
    type = '',
    item = [],
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.created = created;
    this.updated = updated;
    this.isDeleted = isDeleted;
    this.orderBy = orderBy;
    this.type = type;
    this.item = item;
  }
}
