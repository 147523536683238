<template>
  <HotOffersBlock />
</template>

<script>
import HotOffersBlock from "@/components/block-items/HotOffersBlock.vue";

export default {
  name: 'HotOffers',
  components: {
    HotOffersBlock,
  }
}
</script>
