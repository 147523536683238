<template>
  <FactsBlock :page="page"/>
</template>

<script>
import { defineAsyncComponent } from "vue";

const FactsBlock = defineAsyncComponent(() =>
  import("@/components/block-items/FactsBlock.vue")
)
export default {
  name: 'Facts',
  components: {
    FactsBlock
  },
  props: {
    page: null
  }
}
</script>
