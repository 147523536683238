import ProductImage from "@/models/productImage";

export default class CompanyDataPage {
  constructor(
    id ,
    name = '',
    fullName = '',
    phone = '',
    email = '',
    address = '',
    content = '',
    logo = new ProductImage(),
    legalAddress = '',
    inn = '',
    kpp = '',
    ogrn = '',
    ogrnip = '',
    acc = '',
    corrAcc = '',
    bank = '',
    bic = '',
    okved = '',
    taxRegime = '',
    linkMap = '',
  ) {
    this.id = id;
    this.name = name;
    this.fullName = fullName;
    this.phone = phone;
    this.email = email;
    this.address = address;
    this.content = content;
    this.logo = logo;
    this.legalAddress = legalAddress;
    this.inn = inn;
    this.kpp = kpp;
    this.ogrn = ogrn;
    this.ogrnip = ogrnip;
    this.acc = acc;
    this.corrAcc = corrAcc;
    this.bank = bank;
    this.bic = bic;
    this.okved = okved;
    this.taxRegime = taxRegime;
    this.linkMap = linkMap;
  }
}
