import { createStore } from 'vuex';
import {auth} from '@/store/auth.module';
import {api} from '@/store/api.module';
import {loadingState} from "@/store/loading-state.module";
import {notifierMessagesState} from "@/store/norifier-messages-state.module";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    api,
    loadingState,
    notifierMessagesState,
  }
})
