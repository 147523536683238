export default class User {
  constructor(email, password, rememberMe = false, isAgree = false, token = '', refreshToken = '', name = '', phone = '')
  {
    this.email = email;
    this.password = password;
    this.rememberMe = rememberMe;
    this.isAgree = isAgree;
    this.token = token;
    this.refreshToken = refreshToken;
    this.phone = phone;
    this.name = name;
  }
}
