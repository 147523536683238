<template>
  <CallToAction :page="page"/>
  <div v-for="page in pages">
    <div v-if="page.isDeleted != true">
      <HotOffers v-if="page.type === 'HotOfferPage'"/>
      <NewOffers v-if="page.type === 'NewOfferPage'"/>
      <ProductCatalogs v-if="page.type === 'ProductCatalogPage'"/>
      <ServiceCatalogs v-if="page.type === 'ServiceCatalogPage'"/>
      <SalesPromoutionBlock v-if="page.type === 'SalesPromoutionPage'" :page='page'/>
      <PageContent v-if="page.type === 'CustomPage'" :page='page'/>
      <Stages v-if="page.type === 'StagesPage'" :page='page'/>
      <Facts v-if="page.type === 'FactsPage'" :page='page'/>
      <QuestionsAndAnswers v-if="page.type === 'QuestionsAndAnswersPage'" :page='page'/>
      <Reviews v-if="page.type === 'ReviewsPage'" :page='page'/>
      <Partners v-if="page.type === 'PartnersPage'" :page='page'/>
      <Advantages v-if="page.type === 'AdvantagesPage'" :page='page'/>
      <Portfolio v-if="page.type === 'PortfolioPage'" :page='page'/>
    </div>
  </div>
  <FeedbackContent/>
</template>

<script>
import Partners from "@/components/home_page/blocks/Partners.vue";
import Reviews from "@/components/home_page/blocks/Reviews.vue";
import Facts from "@/components/home_page/blocks/Facts.vue";
import QuestionsAndAnswers from "@/components/home_page/blocks/QuestionsAndAnswers.vue";
import Portfolio from "@/components/home_page/blocks/Portfolio.vue";
import Stages from "@/components/home_page/blocks/Stages.vue";
import Advantages from "@/components/home_page/blocks/Advantages.vue";
import PageContent from "@/components/home_page/blocks/PageContent.vue";
import SalesPromoutionBlock from "@/components/block-items/SalesPromoutionBlock.vue";

import Page from "@/models/page";
import FeedbackContent from "@/components/home_page/blocks/FeedbackContent.vue";
import CallToAction from "@/components/home_page/blocks/CallToAction.vue";
import ServiceCatalogs from "@/components/home_page/blocks/ServiceCatalogs.vue";
import ProductCatalogs from "@/components/home_page/blocks/ProductCatalogs.vue";
import ProductCategories from "@/components/home_page/blocks/ProductCategories.vue";
import HotOffers from "@/components/home_page/blocks/HotOffers.vue";
import NewOffers from "@/components/home_page/blocks/NewOffers.vue";
import CallToActionPage from "@/models/call_to_action_page";

export default {
  name: 'PageBlockList',
  components: {
    NewOffers,
    HotOffers,
    ProductCategories,
    ProductCatalogs,
    ServiceCatalogs,
    CallToAction,
    FeedbackContent,
    SalesPromoutionBlock,
    PageContent,
    Advantages,
    Stages,
    Portfolio,
    QuestionsAndAnswers,
    Facts,
    Reviews,
    Partners
  },
  data() {
    return {
      pages: [],
      callToActionPages: [],
      page: new CallToActionPage(),
    };
  },
  mounted() {
    this.getCallToActionPage();
    this.getPages();
  },
  methods: {
    getCallToActionPage() {
      let query = {
        itemsPerPage: 1,
        page: 1,
        isDisabled: false,
      };
      this.$store.dispatch('api/get_call_to_action_pages', query).then(
        response => {
          this.totalRecordsLength = response['hydra:totalItems'];
          this.callToActionPages = response['hydra:member'].map(page => {
            return new CallToActionPage(
              page.id,
              page?.nameFirst ?? '',
              page?.descriptionFirst ?? '',
              page?.nameSecond ?? '',
              page?.descriptionSecond ?? '',
              page?.isDeleted ?? true,
            )
          });
          if (this.totalRecordsLength > 0) {
            this.page = this.callToActionPages[0];
          }
        })
    },
    getPages() {
      this.$store.dispatch('api/get_block_lists', {}).then(
        response => {
          this.pages = response['hydra:member'].map(page => {
            return new Page(
              page.id,
              page.name,
              page.description,
              page.created,
              page.updated,
              page.isDeleted,
              page.orderBy,
              page['@type'],
              page.item
            )});
        },
        error => {
          // console.log(error.response);
        }
      );
    }
  }
}
</script>

