export default function authHeader(method) {
  let token = localStorage.getItem('user.token');
  let contentType = method === 'PATCH' ? 'application/merge-patch+json' : 'application/ld+json';
  if (token) {
    return {
      Authorization: "Bearer " + token,
      'Content-Type': contentType,
    };
    // return { 'x-access-token': user.token };
  }
  return {};
}
