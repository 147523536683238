<template>
  <ServiceCatalogsBlock />
</template>

<script>
import ServiceCatalogsBlock from "@/components/block-items/ServiceCatalogsBlock.vue";

export default {
  name: 'ServiceCatalogs',
  components: {
    ServiceCatalogsBlock,
  }
}
</script>
