export default class Currency {
  constructor(
    id ,
    code = '',
    symbol = '',
    name = ''
  ) {
    this.id = id;
    this.code = code;
    this.symbol = symbol;
    this.name = name;
  }
}
