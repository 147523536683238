<template>
  <NewOffersBlock />
</template>

<script>
import Rating from 'primevue/rating';
import Button from 'primevue/button';

import Products from "@/models/products";
import ProductRatings from "@/models/productRatings";
import NewOffersBlock from "@/components/block-items/NewOffersBlock.vue";

export default {
  name: 'NewOffers',
  components: {
    NewOffersBlock,
  }
}
</script>
