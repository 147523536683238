<template>
  <StagesBlock :page="page"/>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const StagesBlock = defineAsyncComponent(() =>
  import("@/components/block-items/StagesBlock.vue")
)
export default {
  name: 'Stages',
  components: {
    StagesBlock,
  },
  props: {
    page: null
  }
}
</script>
