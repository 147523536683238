<template>
  <ReviewsBlock :page="page"/>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const ReviewsBlock  = defineAsyncComponent(() =>
  import("@/components/block-items/ReviewsBlock.vue")
)

export default {
  name: 'Reviews',
  components: {ReviewsBlock},
  props: {
    page: null
  }
}
</script>
