export default class ProductType {
  constructor(
    id ,
    value = '',
    description = '',
    image = null
  ) {
    this.id = id;
    this.value = value;
    this.description = description;
    this.image = image;
  }
}
