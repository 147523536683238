<template>
  <PageBlockList/>
</template>

<script>
import Advantages from "@/components/home_page/blocks/Advantages.vue";
import Facts from "@/components/home_page/blocks/Facts.vue";
import NavBar from "@/components/NavBar";
import BreadCrumb from "@/components/BreadCrumb";
import CallToAction from "@/components/home_page/blocks/CallToAction.vue";
import Reviews from "@/components/home_page/blocks/Reviews"
import ProductCategories from "@/components/home_page/blocks/ProductCategories.vue";
import ProductCatalogs from "@/components/home_page/blocks/ProductCatalogs.vue";
import ServiceCatalogs from "@/components/home_page/blocks/ServiceCatalogs.vue";
import PageBlockList from "@/components/home_page/blocks/PageBlockList.vue";
import FeedbackContent from "@/components/home_page/blocks/FeedbackContent.vue";

export default {
  name: 'HomeView',
  components: {
    PageBlockList,
    Advantages,
    Facts,
    FeedbackContent,
    NavBar,
    BreadCrumb,
    CallToAction,
    Reviews,
    ProductCategories,
    ProductCatalogs,
    ServiceCatalogs,
  }
}
</script>
