<template>
  <div class="flex justify-content-center">
    <Sidebar v-model:visible="visible">
      <template #container="{ closeCallback }">
        <div id="AdminPanel" class="flex flex-column h-full">
          <div class="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
            <a class="navbar-brand" href="/">
              <img v-if="company?.logo?.link != null" class="product-image-item" :src="company?.logo?.link" :alt="company?.logo?.alt" />
              <span v-if="company?.logo?.link === null">{{ msg }}</span>
            </a>
            <Button
              @click="closeCallback"
              icon="pi pi-times"
              rounded
              outlined></Button>
          </div>
          <ul class="body my-4">
            <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
<!--            <li class="w-100 px-4">-->
<!--              <div-->
<!--                class="py-2 title-menu flex align-items-center justify-content-between"-->
<!--                @click="isShowDirectory = !isShowDirectory">-->
<!--                <div>-->
<!--                  <i class="pi pi-chart-bar"></i>-->
<!--                  ИНСТРУМЕНТЫ-->
<!--                </div>-->
<!--                <i :class="[isShowDirectory === true ? 'pi-chevron-up' : 'pi-chevron-down', 'px-3', 'pi']"></i>-->
<!--              </div>-->
<!--              <ul class="py-2" :class="[isShowDirectory === true ? 'show' : 'hide']">-->
<!--                <li class="p-2 w-100"><a href="#"><i class="pi pi-calendar"></i>Календарь</a></li>-->
<!--                <li class="p-2 w-100"><a href="#"><i class="pi pi-chart-line"></i>Отчеты</a></li>-->
<!--                <li class="p-2 w-100"><a href="#"><i class="pi pi-users"></i>Команда</a></li>-->
<!--              </ul>-->
<!--            </li>-->
<!--            <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />-->
            <li class="w-100 px-4">
              <div
                class="py-2 title-menu flex align-items-center justify-content-between"
                @click="isShowOrder = !isShowOrder">
                <div>
                  <i class="pi pi-shopping-cart"></i>
                  ЗАКАЗЫ
                </div>
                <i :class="[isShowOrder === true ? 'pi-chevron-up' : 'pi-chevron-down', 'px-3', 'pi']"></i>
              </div>
              <ul class="py-2" :class="[isShowOrder === true ? 'show' : 'hide']">
<!--                <li class="p-2 w-100 flex align-items-center justify-content-between">-->
<!--                  <a href="#">-->
<!--                    Товары-->
<!--                  </a>-->
<!--                  <span style="min-width: 1.5rem; height: 1.5rem">3</span>-->
<!--                </li>-->
<!--                <li class="p-2 w-100 flex align-items-center justify-content-between">-->
<!--                  <a href="#">-->
<!--                    Услуги-->
<!--                  </a>-->
<!--                  <span style="min-width: 1.5rem; height: 1.5rem">3</span>-->
<!--                </li>-->
                <li class="p-2 w-100 flex align-items-center justify-content-between">
                  <a href="/feedback-message">
                    Обратная связь клиента
                  </a>
                  <span style="min-width: 1.5rem; height: 1.5rem"></span>
                </li>
              </ul>
            </li>
            <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
            <li class="w-100 px-4">
              <div
                class="py-2 title-menu flex align-items-center justify-content-between"
                @click="isShowManagement = !isShowManagement">
                <div>
                  <i class="pi pi-folder"></i>
                  ПРОЕКТ
                </div>
                <i :class="[isShowManagement === true ? 'pi-chevron-up' : 'pi-chevron-down', 'px-3', 'pi']"></i>
              </div>
              <ul class="py-2" :class="[isShowManagement === true ? 'show' : 'hide']">
                <li class="p-2 w-100"><a href="/get_block_list">Редактор главной страницы</a></li>
                <li class="p-2 w-100"><a href="/products">Товары</a></li>
                <li class="p-2 w-100"><a href="/services">Услуги</a></li>
                <li class="p-2 w-100"><a href="/product-property">Свойства товаров/услуг</a></li>
                <li class="p-2 w-100"><a href="/clients">Клиенты</a></li>
                <li class="p-2 w-100"><a href="/catalog">Каталог</a></li>
                <li class="p-2 w-100"><a href="/catalog-context">Страница каталога</a></li>
                <li class="p-2 w-100"><a href="/types">Типы</a></li>
              </ul>
            </li>
            <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
            <li class="w-100 px-4">
              <div
                class="py-2 title-menu flex align-items-center justify-content-between"
                @click="isShowSeo = !isShowSeo">
                <div>
                  <i class="pi pi-folder"></i>
                  SEO
                </div>
                <i :class="[isShowSeo === true ? 'pi-chevron-up' : 'pi-chevron-down', 'px-3', 'pi']"></i>
              </div>
              <ul class="py-2" :class="[isShowSeo === true ? 'show' : 'hide']">
                <li class="p-2 w-100"><a href="/product-image">Настройка изображений галерея</a></li>
                <li class="p-2 w-100"><a href="/ratings">Рейтинг товаров/услуг</a></li>
              </ul>
            </li>
            <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
            <li class="w-100 px-4">
              <div
                class="py-2 title-menu flex align-items-center justify-content-between"
                @click="isShowSetting = !isShowSetting">
                <div>
                  <i class="pi pi-cog"></i>
                  НАСТРОЙКИ
                </div>
                <i :class="[isShowSetting === true ? 'pi-chevron-up' : 'pi-chevron-down', 'px-3', 'pi']"></i>
              </div>
              <ul class="py-2" :class="[isShowSetting === true ? 'show' : 'hide']">
                <li class="p-2 w-100"><a href="/get_company_data">Данные о компании</a></li>
                <li class="p-2 w-100"><a href="/get_call_to_action_pages">Раздел призыв к действию</a></li>
<!--                <li class="p-2 w-100"><a href="#">Смена пароля</a></li>-->
              </ul>
            </li>
            <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
          </ul>
          <div class="mt-auto">
            <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
            <div class="mx-3">
<!--              <div class="text-center">-->
<!--                <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" shape="circle" />-->
<!--              </div>-->
              <p class="font-bold">Имя: {{user.name}}</p>
              <p class="font-bold">Email: {{user.email}}</p>
              <p class="font-bold">Телефон: {{user.phone}}</p>
            </div>
          </div>
        </div>
      </template>
    </Sidebar>
  </div>
</template>

<script>
import Avatar from 'primevue/avatar';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import User from "@/models/user";

export default {
  name: 'AdminPanel',
  components: {
    Avatar,
    Sidebar,
    Button,
  },
  data() {
    return {
      isShowManagement: false,
      isShowSeo: false,
      isShowOrder: false,
      isShowDirectory: false,
      isShowSetting: false,
      visible: false,
      user: new User('', '', false)
    };
  },
  props: {
    msg: String,
    company: null,
  },
  mounted() {
    this.currentUser()
  },
  methods: {
    showPanel() {
      this.visible = true;
    },
    currentUser() {
      this.user.name = this.$store.state.api?.name;
      this.user.email = this.$store.state.api?.email;
      this.user.phone = this.$store.state.api?.phone;
    },
  }
}
</script>

<style lang="scss">
#AdminPanel {
  a.navbar-brand {
    height: 68px;
    width: 68px;
    img.product-image-item {
      height: 68px;
      width: auto;
    }
  }
  .show {
    padding-left: 0;
    display: block;
  }
  .hide {
    display: none;
  }
  ul.body {
    padding-left: 0;
    overflow: scroll;
    height: calc(100vh - 50vh);
  }
  li {
    display: inline-block;
    margin: 0;
  }
  a {
    color: black!important;
    text-decoration: none!important;
  }
  span {
    float: inline-end;
  }
  button.p-button-text {

  }
  .title-menu {
    cursor: pointer;
  }
}
</style>
