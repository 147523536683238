export default class productPropertyValue {
  constructor(
    id,
    title = '',
    value = '',

  ) {
    this.id = id;
    this.title = title;
    this.value = value;
  }
}
