<template>
  <div class="container pt-2">
    <div class="row">
      <div class="col-12">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" v-for="item in getBreadCrumbPath"><a v-bind:href="item.path">{{item.title}}</a></li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumb',
  computed: {
    getBreadCrumbPath () {
      return this.$route.meta.routePath;
    }
  }
}
</script>

<style lang="scss">
.breadcrumb {
  & li {
    display: inline-block;
    margin: 0!important;
    & a, span {
      color: #374151!important;
    }
    & a {
      text-decoration: none;
    }
    & a:hover {
      color: var(--main-light-text-color);
      text-decoration: none;
      cursor: pointer;
    }
  }
}
</style>
