export default class CallToActionPage {
  constructor(
    id,
    nameFirst = '',
    descriptionFirst = '',
    nameSecond = '',
    descriptionSecond = '',
  ) {
    this.id = id;
    this.nameFirst = nameFirst;
    this.descriptionFirst = descriptionFirst;
    this.nameSecond = nameSecond;
    this.descriptionSecond = descriptionSecond;
  }
}
