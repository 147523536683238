<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 py-4">
        <form @submit.prevent="checkForm">
          <Card class="mx-auto" style="max-width: 46rem; overflow: hidden">
            <template #header>
              <div v-if="logo === true && company?.logo?.link" class="w-100 text-center feedback-logo">
                <img v-if="company?.logo?.link != null" class="product-image-item" :src="company?.logo?.link" :alt="company?.logo?.alt" />
              </div>
            </template>
            <template #title>
              <div class="w-100 text-center">
                {{ title }}
              </div>
            </template>
            <template #subtitle>
              <div class="w-100 text-center">
                {{ subtitle }}
              </div>
            </template>
            <template #content>
              <p class="m-0">
                {{ content }}
              </p>
              <div class="mb-3">
                <label for="inputName" class="form-label">Имя</label>
                <input
                  v-model="user.name"
                  name="name"
                  type="text"
                  class="form-control"
                  id="inputName"
                  placeholder="Петр"
                  aria-describedby="invalidNameFeedback"/>
                <div class="invalid-feedback">
                  Обязяательное поле для заполнения
                </div>
              </div>
              <div class="mb-3">
                <label for="inputPhone" class="form-label">Телефон</label>
                <InputMask
                  v-model="user.phone"
                  name="phone"
                  type="text"
                  class="form-control"
                  id="inputPhone"
                  mask="+7(999) 999-9999"
                  placeholder="+7(999) 999-9999"
                  aria-describedby="invalidPhoneFeedback"/>
                <div class="invalid-feedback">
                  Обязяательное поле для заполнения
                </div>
              </div>
              <div class="mb-3">
                <label for="inputEmail" class="form-label">Email</label>
                <input
                  v-model="user.email"
                  name="email"
                  type="email"
                  class="form-control"
                  id="inputEmail"
                  placeholder="example@mail.com"
                  aria-describedby="invalidEmailFeedback"
                  required>
                <div class="invalid-feedback">
                  Обязяательное поле для заполнения
                </div>
              </div>
              <div class="mb-3">
                <Textarea
                  v-model="message"
                  class="form-control"
                  id="inputMessage"
                  placeholder="Максимум 500 символов"
                  aria-describedby="invalidMessageFeedback"
                  rows="5"
                  maxlength="500"
                  required/>
                <div class="invalid-feedback">
                  Обязяательное поле для заполнения
                </div>
              </div>
            </template>
            <template #footer>
              <div class="flex gap-4 mt-1">
                <div class="w-100 text-center">
                  <button type="submit" class="btn btn-outline-active-style"><span>Отправить</span></button>
                </div>
              </div>
            </template>
          </Card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import User from '../../models/user';
import Card from "primevue/card";
import InputMask from "primevue/inputmask"
import Textarea from 'primevue/textarea';

export default {
  name: 'FeedbackForm',
  props: ['logo', 'title', 'header', 'subtitle', 'content', 'company'],
  components: {
    Card,
    InputMask,
    Textarea,
  },
  data() {
    return {
      message: "",
      user: new User('', '', false)
    };
  },
  mounted() {
  },
  created() {
    this.initFeedbackData();
  },
  computed: {
  },
  methods: {
    initFeedbackData() {
      this.user.name = this.$store.state.api?.name ? "" : this.$store.state.api?.name;
      this.user.email = this.$store.state.api?.email;
      this.user.phone = this.$store.state.api?.phone ? "" : this.$store.state.api?.phone;
    },
    checkForm: function (e) {
      if (!this.validEmail(this.user.email)) {
        return;
        e.preventDefault();
      }

      this.sendFeedbackMessage();
    },
    validEmail: function (email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendFeedbackMessage() {
      if (this.user.email && this.user.phone && this.user.name) {
        let data = {
          userName: this.user.name,
          userEmail: this.user.email,
          userPhone: this.user.phone,
          title: this.title,
          message: this.message,
        }
        this.$store.dispatch('api/send_feedback_message', data).then(
          response => {
            //console.log(response);
            this.$emit("close");
          },
          error => {
            // console.log(error.response);
            this.$emit("close");
          }
        );
      }
    }
  }
};
</script>
<style lang="scss">
  .p-card {
    box-shadow: unset;
  }
  .feedback-logo {
    img {
      height: 122px;
      width: 122px;
    }
  }
</style>
