<template>
  <PortfolioBlock :page="page"/>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const PortfolioBlock = defineAsyncComponent(() =>
  import("@/components/block-items/PortfolioBlock.vue")
)

export default {
  name: 'Portfolio',
  components: {PortfolioBlock},
  props: {
    page: null
  },
}
</script>
