<template>
  <div id="SalesPromoutionBlock" class="container my-2">
    <div class="row row-cols-lg-6 row-cols-md-12 row-cols-sm-12 g-4 my-2 mx-auto">
      <div class="col-lg-3 col-mb-3 col-sm-12" v-for="(item, index) in page.item?.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1).slice(0, 4)">
        <div class="card" v-if="page.item.isDeleted != true && index < 3">
          <a :href="'/get_sales_promoution_page_details/' + item.id">
            <div class="card-body cover" :style="[item.image?.link ? { backgroundImage: 'url(' + item.image?.link + ')' } : '']">
            </div>
            <div class="sales-header text-center w-100 mx-auto">
              <h5 class="my-2">{{ item.title }}</h5>
            </div>
          </a>
        </div>
        <div class="card" v-if="page.item.isDeleted != true  && index === 3">
          <a href="/get_all_sales_promoution_page">
            <img class="sales-img w-100" src="@/assets/sales.jpeg" alt="enzhtech.ru" />
            <div class="sales-header text-center w-100 mx-auto">
              <h5 class="my-2">Все акции</h5>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SalesPromoutionBlock',
  props: {
    page: null
  }
}
</script>


<style lang="scss">
#SalesPromoutionBlock {
  .card .card-body {
    min-height: 288px;
    & .card-title {
      height: 4.2rem;
      font-weight: 400;
      font-size: x-large;
    }
  }
  .sales-header {
    position: absolute;
    margin-bottom: 0px;
    margin-top: -6.4rem;
    background: rgba(255, 255, 255, 0.6);
    min-height: 4.4rem;
    color: var(--main-dark-color);
  }
}
</style>
