<template>
  <div id="FeedbackForm" class="feedback-form mt-4">
    <FeedbackForm
      :logo='logo'
      :title='title'
      :header='header'
      :subtitle='subtitle'
      :content='content'
      :company='company'
    />
  </div>
</template>

<script>
import FeedbackForm from "@/components/feedback-form/FeedbackForm.vue";
import CompanyDataPage from "@/models/company_data_page";
import ProductImage from "@/models/productImage";

export default {
  name: 'FeedbackContent',
  components: {FeedbackForm},
  data() {
    return {
      logo: true,
      title: "Форма обратной связи на главной странице",
      header: "Остались вопросы",
      subtitle: "Заполните форму обратной связи и мы с вами свяжемся",
      content: "",
      company: new CompanyDataPage(),
    }
  },
  mounted() {
    this.getCompanyData();
  },
  methods: {
    getCompanyData() {
      let query = {
        itemsPerPage: 1,
        page: 1,
      };
      this.$store.dispatch('api/get_company_data', query).then(
        response => {
          this.totalRecordsLength = response['hydra:totalItems'];
          this.pages = response['hydra:member'].map(page => {
            return new CompanyDataPage(
              page.id,
              page?.name ?? '',
              page?.fullName ?? '',
              page?.phone ?? '',
              page?.email ?? '',
              page?.address ?? '',
              page?.content ?? '',
              new ProductImage(
                page?.logo?.id ?? null,
                page?.logo?.title ?? '',
                page?.logo?.description ?? '',
                page?.logo?.created ?? '',
                page?.logo?.updated ?? '',
                page?.logo?.original_title ?? '',
                page?.logo?.bucket ?? '',
                page?.logo?.link ?? '',
                page?.logo?.type ?? '',
                page?.logo?.product ?? null,
                page?.logo?.alt ?? '',
              ),
              page?.legalAddress ?? '',
              page?.inn ?? '',
              page?.kpp ?? '',
              page?.ogrn ?? '',
              page?.ogrnip ?? '',
              page?.acc ?? '',
              page?.corrAcc ?? '',
              page?.bank ?? '',
              page?.bic ?? '',
              page?.okved ?? '',
              page?.taxRegime ?? '',
              page?.linkMap ?? '',
            )
          });
          if (this.totalRecordsLength > 0) {
            this.files = null;
            this.company = this.pages[0];

          }
        },
        error => {
          // console.log(error.response);
        }
      );
    },
  }
}
</script>
<style lang="scss">
h3 {
  margin: 40px 0 0;
}
</style>
