<template>
  <div v-html="content"></div>
</template>

<script>
export default {
  name: 'PageContent',
  data() {
    return {
      content: ""
    }
  },
  props: {
    page: null
  },
  mounted() {
    this.getContent();
  },
  methods: {
    getContent() {
      this.$store.dispatch('api/get_custom_page', this.page).then(
        response => {
          this.content = response.content;
        },
        error => {
          // console.log(error.response);
        }
      );
    }
  }
}
</script>
