import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';

// import 'primevue/resources/themes/lara-light-green/theme.css'
import './assets/theme.css';
import 'primeicons/primeicons.css'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const app = createApp(App);

app.use(PrimeVue)
  .use(store)
  .use(router)
  .use(ConfirmationService)
  .mount('#app');

