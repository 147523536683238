import ProductImage from "@/models/productImage";
import ProductPropertyValue from "@/models/productPropertyValue";
import ProductCategoryTree from "@/models/productCategoryTree";
import ProductType from "@/models/productType";
import ProductRatings from "@/models/productRatings";
import ProductContext from "@/models/productContext";
import ProductPrice from "@/models/productPrice";

export default class Products {
  constructor(
    id ,
    name = '',
    description = '',
    created = '',
    updated = '',
    productImage = new ProductImage(),
    productPropertyValue = new ProductPropertyValue(),
    productCategory = new ProductCategoryTree(),
    isDeleted = false,
    productType = new ProductType(),
    productRating = new ProductRatings(),
    productContext= new ProductContext(),
    productPrice= new ProductPrice(),
    isService = false,
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.created = created;
    this.updated = updated;
    this.productImage = productImage;
    this.productPropertyValue = productPropertyValue;
    this.productCategory = productCategory;
    this.isDeleted = isDeleted;
    this.productType = productType;
    this.productRating = productRating;
    this.productContext = productContext;
    this.productPrice = productPrice;
    this.isService = isService;
  }
}
