<template>
  <div id="CallToAction">
    <div class="container py-4">
      <div class="row align-items-md-stretch">
        <div class="col-md-6">
          <div class="h-100 p-5 text-white rounded-3" v-if="page.nameFirst || page.descriptionFirst">
            <h2>{{page.nameFirst}}</h2>
            <p>{{page.descriptionFirst}}</p>
            <a href="/#FeedbackForm" class="btn btn-outline-light-style" type="button"><span>Заказать сейчас</span></a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="h-100 p-5 bg-light border rounded-3" v-if="page.nameSecond || page.descriptionSecond">
            <h2>{{page.nameSecond}}</h2>
            <p>{{page.descriptionSecond}}</p>
            <a href="/product-price?rangePrice=0&rangePrice=100000" class="btn btn-outline-active-style" type="button"><span>Перейти</span></a>
          </div>
        </div>
      </div>
    </div>
    <ProductCategories/>
  </div>
</template>

<script>
import ProductCategories from "@/components/home_page/blocks/ProductCategories.vue";
import CallToActionPage from "@/models/call_to_action_page";

export default {
  name: 'CallToAction',
  components: {ProductCategories},
  props: {
    page: new CallToActionPage()
  },
}
</script>


<style lang="scss">
#CallToAction {
  background-size: cover;
  background-color: var(--main-dark-color);
  background-image: url("@/assets/back.jpg");
  height: 100%;
  a.btn {
    border: solid 1px var(--main-action-color);
  }
}
.call-to-action {
  font-size: x-large;
  color: var(--main-action-color);
}
</style>
