import Currency from "@/models/currency";
export default class ProductPrice {
  constructor(
    id,
    value = 0,
    currency = new Currency()
  ) {
    this.id = id;
    this.value = value;
    this.currency = currency;
  }
}
