<template>
  <footer class="footer py-3">
    <div class="container">
      <div v-if="company?.logo?.link" class="row mb-4 p-4" style="min-height: auto; ">
        <div class="footer-promo mx-auto col-12 py-4">
          <div class="row company-info">
            <div class="col-lg-4 col-mb-4 col-sm-12 text-center my-auto">
              <img class="product-image-item" :src="company?.logo?.link" :alt="company?.logo?.alt" />
            </div>
            <div class="col-lg-8 col-mb-8 col-sm-12 text-center my-auto">
              <div>
                {{company.name}}
              </div>
              <div class="pt-2">
                <a :href="'tel:' + company.phone" class="w-100">{{company.phone}}</a>
              </div>
              <div class="pt-2">
                <a :href="'mailto:' + company.email" class="w-100">{{company.email}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row company-info">
        <div class="col-lg-3 col-mb-3 col-sm-12">
          <p>{{company.fullName}}</p>
          <p v-if="company?.inn">ИНН: {{company.inn}}</p>
          <p v-if="company?.kpp">KПП: {{company.kpp}}</p>
          <p v-if="company?.address">Адрес: {{company.address}}</p>
          <a v-if="company?.linkMap" :href="'https://yandex.ru/maps/?' + company.linkMap" alt="" style="border: 0;">
            <img class="map mb-4" :src="'https://api-maps.yandex.ru/services/constructor/1.0/static/?' + company.linkMap + ';width=100&amp;height=200&amp;lang=ru_RU'" alt="" style="border: 0;" />
          </a>
        </div>
        <div class="col-lg-3 col-mb-3 col-sm-12">
          <h5>Мы в соцсетях</h5>
          <ul>
<!--            <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>-->
<!--            <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>-->
<!--            <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>-->
<!--            <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>-->
<!--            <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>-->
          </ul>
        </div>
        <div class="col-lg-3 col-mb-3 col-sm-12">
          <h5>Наши проекты</h5>
          <ul>
<!--            <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>-->
<!--            <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>-->
<!--            <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>-->
<!--            <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>-->
<!--            <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>-->
          </ul>
        </div>
        <div class="col-lg-3 col-mb-3 col-sm-12">
          <h5>Конфиденциальность</h5>
          <ul>
<!--            <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>-->
<!--            <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>-->
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String,
    company: null
  }
}
</script>


<style lang="scss">
.footer {
  background-color: var(--main-dark-color);
  color: aliceblue;
  margin-top: 80px;
  .company-info{
    li {
      a {
        color: var(--main-action-color)!important;
        text-decoration: unset;
      }
    }
    a {
      color: var(--main-dark-color)!important;
      text-decoration: unset;
    }
  }
  img.product-image-item {
    height: 120px;
    margin: 0px;
    padding: 8px;
  }
  & ul {
    list-style-type: none;
    padding: 0;
  }
  & li {
    margin: 0 10px;
  }
  & a, span {
    color: var(--main-action-color);
  }
  & .footer-promo {
      background:  var(--main-action-color);
      color: var(--main-dark-color);
      width: auto;
      height: auto;
      margin-top: -80px;
    & img.border-round {
      height: auto;
      width: max-content;
    }
  }
}
</style>
