<template>
  <nav id="NavBar" class="navbar navbar-expand-lg navbar-light">
    <div class="container">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand" href="/">
        <img v-if="company?.logo?.link != null" class="product-image-item" :src="company?.logo?.link" :alt="company?.logo?.alt" />
        <span v-if="company?.logo?.link === null">{{ msg }}</span>
      </a>
      <div class="collapse navbar-collapse bg-light" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link nav-start" href="/about">О нас</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link nav-start dropdown-toggle" href="#" tabindex="-1" aria-expanded="false" @click="isShow = !isShow">
              Каталог
            </a>
          </li>
          <li v-if="adminUser" class="nav-item dropdown">
            <a class="nav-link nav-start" href="#" tabindex="-1" aria-expanded="false" @click="showAdminPanel()">
              Личный кабинет
            </a>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
          <li v-if="!currentUser" class="nav-item">
            <a class="nav-link nav-end" href="/login">
              Вход
            </a>
          </li>
          <li v-if="!currentUser" class="nav-item">
            <a class="nav-link nav-end" href="/registration"><span>Регистрация</span></a>
          </li>
          <li v-if="currentUser" class="nav-item">
            <a class="nav-link nav-end" href="#" @click.prevent="logOut">
              Выход
            </a>
          </li>
        </ul>
<!--        <ul class="navbar-nav ml-auto mb-2 mb-lg-0">-->
<!--          <li class="nav-item px-4">-->
<!--            <a href="#" class="nav-link">-->
<!--              <div class="cart btn">-->
<!--                <i class="pi pi-shopping-cart"></i>-->
<!--                <span class="badge rounded-pill"> 99+ </span>-->
<!--              </div>-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
      </div>
    </div>
  </nav>
  <div id="NavbarDropdown">
    <div :class="[isShow === true ? 'show' : 'hide', 'select-list']">
      <div class="container">
        <div class="row mx-auto text-start">
          <div class="col-sm-12 col-md col-lg col-xl col-xxl menu-col" v-for="item in productCategoryTree">
            <ul class="children">
              <li>
                <a class="" :href="'/product-price?category='+item.key+'&rangePrice=0&rangePrice=100000'">
                  {{ item.label }}
                </a>
              </li>
              <li clss="pt-4" v-for="item in item.children">
                <a class="" :href="'/product-price?category='+item.key+'&rangePrice=0&rangePrice=100000'">
                  {{ item.label }}
                </a>
                <ul class="children">
                  <li clss="pt-2" v-for="item in item.children">
                    <a class="" :href="'/product-price?category='+item.key+'&rangePrice=0&rangePrice=100000'">
                      {{ item.label }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <Divider />
        <div class="row text-center">
          <div class="col-12 w-100 ">
            <a class="nav-link" href="#" tabindex="-1" aria-expanded="false" @click="isShow = !isShow">
              Закрыть
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FastSearchQuery/>
  <AdminPanel
    v-if="adminUser"
    :msg="msg"
    :company="company"
    ref="adminPanelComponent"/>
</template>

<script>
import FastSearchQuery from "@/components/fast_search/FastSearchQuery";
import Avatar from 'primevue/avatar';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import Divider from "primevue/divider";
import AdminPanel from "@/components/admin-panel/panel/AdminPanel.vue";

export default {
  name: 'NavBar',
  components: {
    AdminPanel,
    FastSearchQuery,
    Avatar,
    Sidebar,
    Button,
    Divider,
  },
  data() {
    return {
      productCategoryTree: [],
      isShow: false,
    };
  },
  mounted() {
    this.getProductCategoryTree();
  },
  computed: {
    currentUser() {
      return this.$store.state.auth?.token ?? false;
    },
    adminUser() {
      if (typeof this.$store.state.api?.isAdmin === 'string') {
        return this.$store.state.api?.isAdmin === 'true' ? true : false;
      }

      return this.$store.state.api?.isAdmin === true ? true : false;
    },
  },
  methods: {
    routeToPageBoard(data) {
      this.$router.push(
        {
          path: 'product-price',
          query: {
            category: data.id,
            rangePrice: [0, 100000],
          }
        }
      );
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    getTreeNodes(items, id = null) {
      return items
        .filter(item => item?.parent?.id === id)
        .map(item => ({
          key: item.id,
          label: item.title,
          children: this.getTreeNodes(items, item.id)
        }));
    },
    getProductCategoryTree() {
      this.$store.dispatch('api/get_product_category', {}).then(
        response => {
          let productCategoryTreeMap = response['hydra:member'].map((productCategory) => {
            return { ...productCategory, parent: productCategory?.parent ?? {id: null}};
          });

          this.productCategoryTree = this.getTreeNodes(productCategoryTreeMap);
        },
        error => {
          // console.log(error.response);
        }
      );
    },
    showAdminPanel() {
      this.$refs.adminPanelComponent.showPanel();
    },
  },
  props: {
    msg: String,
    company: null
  }
}
</script>

<style lang="scss">
  @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");
  #NavBar {
    a.navbar-brand {
      height: 68px;
      width: 68px;
      img.product-image-item {
        height: 68px;
        width: auto;
      }
    }
    a, nav {
      color: var(--main-body-text-color);
      & p {
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
      }
    }
    div#navbarSupportedContent {
      margin: 1.2rem 0;
      & a {
        font-weight: 500;
      }
    }
    .badge {
      font-size: small;
      background-color: var(--main-action-color);
    }
    i {
      color: var(--main-action-color);
      font-size: x-large;
    }
  }
  #NavbarDropdown {
    .show {
      margin: 0;
      padding: 15px 0 0 0;
      overflow: auto;
      max-height: calc(100vh - 180px);
      -webkit-transition: max-height 0.5s;
      -moz-transition: max-height 0.5s;
      -ms-transition: max-height 0.5s;
      -o-transition: max-height 0.5s;
      transition: max-height 0.5s;
    }

    .hide {
      max-height: 0px;
      display: none;
      -webkit-transition: max-height 0.5s;
      -moz-transition: max-height 0.5s;
      -ms-transition: max-height 0.5s;
      -o-transition: max-height 0.5s;
      transition: max-height 0.5s;
    }

    @media only screen and (max-width: 1024px) {
      div.select-list {
        top: 148px!important;
      }
    }

    div.select-list {
      padding: 1rem;
      background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
      border: var(--bs-border-width) solid var(--bs-border-color);
      border-radius: var(--bs-border-radius);
      position: absolute;
      width: 100vw;
      top: 164px;
      z-index: 999;
      & div {
        & ul.children {
          display: grid;
          margin-left: 0px;
          padding-left: 0px;
          & li {
            margin-left: 0px;
            padding-left: 0px;
            margin-top: 1.2rem;
            white-space: nowrap;
          }
        }
      }
      li {
        margin: 0;
        padding: 0;
        width: 100%;
        cursor: default;
        p {
          margin: 0 15px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
          white-space: pre-wrap;
        }
      }
    }
    .badge {
      font-size: small;
      background-color: var(--main-action-color);
    }
    i {
      color: var(--main-action-color);
      font-size: x-large;
    }
  }
</style>
