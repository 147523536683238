export const loadingState = {
  namespaced: true,
  state: {
    loading: false
  },
  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
  }
}
