import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      breadcrumb: {'title': 'Главная'}
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (Login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Login" */ '../views/auth/Login.vue')
  },
  {
    path: '/registration',
    name: 'Registration',
    // route level code-splitting
    // this generates a separate chunk (Registration.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Registration" */ '../views/auth/Registration.vue')
  },
  {
    path: '/verify/email',
    name: 'VerifyEmail',
    // route level code-splitting
    // this generates a separate chunk (VerifyEmail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "VerifyEmail" */ '../views/auth/VerifyEmail.vue'),
  },
  {
    path: '/forget_password',
    name: 'ForgetPassword',
    // route level code-splitting
    // this generates a separate chunk (ForgetPassword.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ForgetPassword" */ '../views/auth/ForgetPassword.vue')
  },
  {
    path: '/verify/reset_password',
    name: 'VerifyResetPassword',
    // route level code-splitting
    // this generates a separate chunk (VerifyResetPassword.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "VerifyResetPassword" */ '../views/auth/VerifyResetPassword.vue')
  },
  {
    path: '/conditions',
    name: 'ConditionsVue',
    // route level code-splitting
    // this generates a separate chunk (VerifyResetPassword.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "VerifyResetPassword" */ '../views/ConditionsVue.vue'),
    meta: {
      breadcrumb: { title: 'Условия использования' }
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      breadcrumb: { title: 'О нас' }
    }
  },
  {
    path: '/clients',
    name: 'clients',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/ClientsBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Клиенты' }
    }
  },
  {
    path: '/hot-offers',
    name: 'hot-offers',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "products" */ '../views/admin-panel/HotOffersBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Горячие предложения' }
    }
  },
  {
    path: '/new-offers',
    name: 'new-offers',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "products" */ '../views/admin-panel/NewOffersBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Новинки' }
    }
  },
  {
    path: '/product-catalog',
    name: 'product-catalog',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "products" */ '../views/admin-panel/ProductCatalogsBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Каталог товаров' }
    }
  },
  {
    path: '/service-catalog',
    name: 'service-catalog',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "products" */ '../views/admin-panel/ServiceCatalogsBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Услуги' }
    }
  },
  {
    path: '/products',
    name: 'products',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "products" */ '../views/admin-panel/ProductsBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Товары' }
    }
  },
  {
    path: '/product/:id',
    name: 'product',
    // route level code-splitting
    // this generates a separate chunk (ListFiles.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "product" */ '../views/admin-panel/ProductBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Карточка товара' }
    }
  },
  {
    path: '/services',
    name: 'services',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "products" */ '../views/admin-panel/ServicesBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Услуги' }
    }
  },
  {
    path: '/service/:id',
    name: 'service',
    // route level code-splitting
    // this generates a separate chunk (ListFiles.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "product" */ '../views/admin-panel/ServiceBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Карточка услуги' }
    }
  },
  {
    path: '/product-view/:id',
    name: 'product-view',
    // route level code-splitting
    // this generates a separate chunk (ListFiles.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "product" */ '../views/client-panel/ProductForClientBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Карточка товара' }
    }
  },
  {
    path: '/product-price',
    name: 'product-price',
    // route level code-splitting
    // this generates a separate chunk (ListFiles.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "product" */ '../views/client-panel/ProductPriceListForClientBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Каталог' }
    }
  },
  {
    path: '/product-image',
    name: 'product-image',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "product-image" */ '../views/admin-panel/ProductImageBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Галерея' }
    }
  },
  {
    path: '/catalog',
    name: 'catalog',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/CatalogBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Каталог' }
    }
  },
  {
    path: '/catalog-context',
    name: 'catalog-context',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/CatalogContextBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Страница каталога' }
    }
  },
  {
    path: '/types',
    name: 'types',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/ProductTypesBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Типы' }
    }
  },
  {
    path: '/ratings',
    name: 'ratings',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/ProductRatingBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Рейтинг' }
    }
  },
  {
    path: '/product-property',
    name: 'product-property',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/ProductPropertyBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Свойства' }
    }
  },
  {
    path: '/order',
    name: 'order',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "order" */ '../views/client-panel/OrderBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Заказы' }
    }
  },
  {
    path: '/feedback-message',
    name: 'feedback-message',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/FeedbackMessageBoardView.vue'),
    meta: {
      breadcrumb: { title: 'Обратная связь клиента' }
    }
  },
  {
    path: '/get_block_list',
    name: 'get_block_list',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/BlockListView.vue'),
    meta: {
      breadcrumb: { title: 'Редактор главной страницы' }
    }
  },
  {
    path: '/get_sales_promoution_pages',
    name: 'get_sales_promoution_pages',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/SalesPromoutionPagesView.vue'),
    meta: {
      breadcrumb: { title: 'Список акций' }
    }
  },
  {
    path: '/get_sales_promoution_page/:id',
    name: 'get_sales_promoution_page',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/SalesPromoutionPageView.vue'),
    meta: {
      breadcrumb: { title: 'Акции' }
    }
  },
  {
    path: '/get_all_sales_promoution_page',
    name: 'get_all_sales_promoution_page',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/sales-promoution/AllSalesPromoutionList.vue'),
    meta: {
      breadcrumb: { title: 'Акции' }
    }
  },
  {
    path: '/get_sales_promoution_page_details/:id',
    name: 'get_sales_promoution_page_details',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/sales-promoution/SalesPromoutionDetails.vue'),
    meta: {
      breadcrumb: { title: 'Акции' }
    }
  },
  {
    path: '/get_custom_pages',
    name: 'get_custom_pages',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/CustomPagesView.vue'),
    meta: {
      breadcrumb: { title: 'Список кастомизированнных блоков страниц' }
    }
  },
  {
    path: '/get_custom_page/:id',
    name: 'get_custom_page',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/CustomPageView.vue'),
    meta: {
      breadcrumb: { title: 'Кастомизированнный блок страницы' }
    }
  },
  {
    path: '/get_company_data',
    name: 'get_company_data',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/CompanyDataPageView.vue'),
    meta: {
      breadcrumb: { title: 'О компании' }
    }
  },
  {
    path: '/get_call_to_action_pages',
    name: 'get_call_to_action_pages',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/CallToActionPagesView.vue'),
    meta: {
      breadcrumb: { title: 'Список CallToAction блоков страниц' }
    }
  },
  {
    path: '/get_call_to_action_page/:id',
    name: 'get_call_to_action_page',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/CallToActionPageView.vue'),
    meta: {
      breadcrumb: { title: 'CallToAction блок страницы' }
    }
  },
  {
    path: '/get_stages_pages',
    name: 'get_stages_pages',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/StagesPagesView.vue'),
    meta: {
      breadcrumb: { title: 'Список Stages блоков страниц' }
    }
  },
  {
    path: '/get_stages_page/:id',
    name: 'get_stages_page',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/StagesPageView.vue'),
    meta: {
      breadcrumb: { title: 'Stages блок страницы' }
    }
  },
  {
    path: '/get_facts_pages',
    name: 'get_facts_pages',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/FactsPagesView.vue'),
    meta: {
      breadcrumb: { title: 'Список Facts блоков страниц' }
    }
  },
  {
    path: '/get_facts_page/:id',
    name: 'get_facts_page',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/FactsPageView.vue'),
    meta: {
      breadcrumb: { title: 'Facts блок страницы' }
    }
  },
  {
    path: '/get_questions_and_answers_pages',
    name: 'get_questions_and_answers_pages',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/QuestionsAndAnswersPagesView.vue'),
    meta: {
      breadcrumb: { title: 'Список questions and answers блоков страниц' }
    }
  },
  {
    path: '/get_questions_and_answers_page/:id',
    name: 'get_questions_and_answers_page',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/QuestionsAndAnswersPageView.vue'),
    meta: {
      breadcrumb: { title: 'Questions_and_answers блок страницы' }
    }
  },
  {
    path: '/get_reviews_pages',
    name: 'get_reviews_pages',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/ReviewsPagesView.vue'),
    meta: {
      breadcrumb: { title: 'Список reviews блоков страниц' }
    }
  },
  {
    path: '/get_reviews_page/:id',
    name: 'get_reviews_page',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/ReviewsPageView.vue'),
    meta: {
      breadcrumb: { title: 'Reviews блок страницы' }
    }
  },
  {
    path: '/get_partners_pages',
    name: 'get_partners_pages',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/PartnersPagesView.vue'),
    meta: {
      breadcrumb: { title: 'Список partners блоков страниц' }
    }
  },
  {
    path: '/get_partners_page/:id',
    name: 'get_partners_page',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/PartnersPageView.vue'),
    meta: {
      breadcrumb: { title: 'Partners блок страницы' }
    }
  },
  {
    path: '/get_advantages_pages',
    name: 'get_advantages_pages',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/AdvantagesPagesView.vue'),
    meta: {
      breadcrumb: { title: 'Список advantages блоков страниц' }
    }
  },
  {
    path: '/get_advantages_page/:id',
    name: 'get_advantages_page',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/AdvantagesPageView.vue'),
    meta: {
      breadcrumb: { title: 'Advantages блок страницы' }
    }
  },
  {
    path: '/get_portfolio_pages',
    name: 'get_portfolio_pages',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/PortfolioPagesView.vue'),
    meta: {
      breadcrumb: { title: 'Список portfolio блоков страниц' }
    }
  },
  {
    path: '/get_portfolio_page/:id',
    name: 'get_portfolio_page',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin-panel/PortfolioPageView.vue'),
    meta: {
      breadcrumb: { title: 'Portfolio блок страницы' }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    // route level code-splitting
    // this generates a separate chunk (NotFoundPage.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "NotFoundPage" */ '../views/NotFound.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const routePath = [];

router.beforeEach((to, from, next) => {
  routePath.push({ name: 'home', path: '/', title: 'Главная'});
  if(to.meta?.breadcrumb) {
    routePath.push({ name: to.name, path: to.path, title: to.meta.breadcrumb.title});
  }
  to.meta.routePath = routePath.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.name === value.name
      ))
  )
  next();
});

export default router
