<template>
  <PartnersBlock :page="page"/>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const PartnersBlock = defineAsyncComponent(() =>
  import("@/components/block-items/PartnersBlock.vue")
)

export default {
  name: 'Partners',
  components: {PartnersBlock},
  props: {
    page: null
  },
}
</script>
