<template>
  <div id="FastSearch" class="pb-4 border-bottom">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-mx-12 col-lg-12 mb-2">
          <form class="d-flex" @submit.prevent="onSubmitFastSearch">
            <input
              @input="search"
              v-model="query"
              autocomplete="off"
              type="text"
              class="form-control me-2 fast-search-input"
              id="dataListFSQ"
              placeholder="Поиск"
              aria-label="Search">
            <button class="btn btn-outline-active-style" type="submit"><span>Найти</span></button>
          </form>
          <ul :class="[isShow === true ? 'show' : 'hide', 'select-list']">
            <li v-for="product in products" class="pb-2" @click="setProduct(product)">
              <p>{{product.name}}</p>
              <p v-html="product.description"></p>
            </li>
            <li v-if="suggest" @click="setSuggets()">
              <Divider />
              <p v-html="suggest" class="pb-3"></p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Divider from 'primevue/divider';
export default {
  name: 'FastSearchQuery',
  components: {
    Divider,
  },
  data() {
    return {
      products: null,
      isShow: false,
      query: '',
      suggest: null,
      suggestValue: null,
    };
  },
  watch: {
    $route (){
      this.query = this.query !== '' ? this.query : this.$route.query.query;
    }
  },
  methods: {
    onSubmitFastSearch() {
      if(this.query?.trim()){
        this.handleFastSearchQuery();
      }
    },
    search() {
      if (!this.query?.trim() || this.query?.length < 3) {
        this.isShow = false;
        this.products = [];
        return;
      };
      this.$store.dispatch('api/suggest_product', {"query": this.query}).then(
        response => {
          if (response.suggest) {
            this.suggest = response.suggest?.length > 0 ? 'Возможно вы имели ввиду ' + response.suggest.join(', ') : null;
            this.suggestValue = response.suggestValue?.length > 0 ? response.suggestValue[0] : null
          }
        },
        error => {
          // console.log(error.response);
        }
      );
      this.$store.dispatch('api/search_product', {"query": this.query}).then(
        response => {
          this.products = response.product;
          this.isShow = response?.product?.length > 0 ?? false;
        },
        error => {
          // console.log(error.response);
        }
      );
    },
    handleFastSearchQuery() {
      this.$router.push(
        {
          name: 'product-price',
          query: {
            query: this.query?.trim(),
            rangePrice: [0, 100000],
          }
        }
      )
    },
    setSuggets() {
      if (this.suggestValue !== null) {
        this.query = this.suggestValue;
        this.isShow = false;
      }
    },
    setProduct(product) {
      if (product?.name !== null) {
        this.query = product.name;
        this.isShow = false;
      }
    }
  }
}
</script>

<style lang="scss">
#FastSearch {
  .show {
    margin: 0;
    padding: 15px 0 0 0;
    overflow: auto;
    max-height: 240px;
    -webkit-transition: max-height 0.5s;
    -moz-transition: max-height 0.5s;
    -ms-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    transition: max-height 0.5s;
  }

  .hide {
    max-height: 0px;
    display: none;
    -webkit-transition: max-height 0.5s;
    -moz-transition: max-height 0.5s;
    -ms-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    transition: max-height 0.5s;
  }

  ul.select-list {
    position: relative;
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    z-index: 9999;
    li {
      margin: 0;
      padding: 0;
      width: 100%;
      cursor: default;
      p {
        margin: 0 15px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
        white-space: pre-wrap;
      }
    }
  }
}
</style>
