<template>
  <div class="toast-container w-100" v-if="getMessages.message != null">
    <div class="toast show ms-auto me-3" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header bg-light w-100">
        <i class="pi pi-info-circle"></i>
        <strong class="mx-2">{{'Сообщение'}}</strong>
        <small class="text-muted">{{getMessages?.time ?? '3 секунды назад'}}</small>
        <button type="button" class="btn-close ml-auto" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        <p v-if="getMessages.message.message">
          {{ getMessages.message.message }}
        </p>
        <p v-for="error in getMessages.errors">
          {{ error.message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Notifier',
  computed: {
    getMessages () {
      return this.$store.state.notifierMessagesState.messages;
    }
  }
}
</script>

<style lang="scss">
.toast-container {
  position: fixed!important;
  bottom: 24px!important;

  & button.btn-close {
    margin-left: auto;
  }
}
</style>
