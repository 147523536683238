<template>
  <Dialog
    header="Оформление услуги"
    v-model:visible="visible">
    <div id="ServiceDialog" class="feedback-form">
      <FeedbackForm
        :logo='logo'
        :title='title'
        v-on:close="closeModal"/>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import Calendar from 'primevue/calendar';
import Textarea from 'primevue/textarea';
import Rating from 'primevue/rating';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import TreeSelect from 'primevue/treeselect';
import Button from 'primevue/button';
import FileUpload from 'primevue/fileupload';
import Badge from 'primevue/badge';
import ProgressBar from 'primevue/progressbar';
import FeedbackForm from "@/components/feedback-form/FeedbackForm.vue";

export default {
  name: 'ServiceDialogModal',
  data() {
    return {
      visible: false,
      logo: true,
      title: "",

      editData: null,
      files: [],
      totalSize: 0,
      totalSizePercent: 0,
    };
  },
  components: {
    FeedbackForm,
    Dialog,
    InputText,
    InputMask,
    Calendar,
    Textarea,
    Rating,
    InputNumber,
    Dropdown,
    TreeSelect,
    Button,
    FileUpload,
    Badge,
    ProgressBar,
  },
  methods: {
    showModal(data) {
      this.title += data.name;
      this.visible = true;
    },
    closeModal() {
      this.title = '';
      this.visible = false;
    },
  },
  props: {
    formItems: Array,
  }
};
</script>

<style lang="scss">
.p-dialog{
  min-width: 50vw;
  & button.p-button-text {
    min-width: 48px;
    border-color: var(--main-action-color);

  }
  & button.p-button-danger {
    border-color: #EF4444;
  }
  & i {
    font-size: medium;
  }
  & .form-label {
    margin-bottom: 0.5rem;
    display: block;
  }
  .p-card {
    box-shadow: unset;
  }
}

</style>



