<template>
  <header class="w-100 bg-light">
    <NavBar msg="LOGO" :company="company"/>
    <BreadCrumb/>
    <Progress/>
  </header>
</template>

<script>
import NavBar from "@/components/NavBar";
import BreadCrumb from "@/components/BreadCrumb";
import Progress from "@/components/Progress";


export default {
  name: 'Header',
  components: {
    Progress,
    NavBar,
    BreadCrumb,
  },
  props: {
    company: null
  }
}
</script>

<style lang="scss">
header {
  position: sticky;
  top: 0px;
  z-index: 200;
}
</style>
