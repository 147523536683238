<template>
  <QuestionsAndAnswersBlock :page="page"/>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const QuestionsAndAnswersBlock  = defineAsyncComponent(() =>
  import("@/components/block-items/QuestionsAndAnswersBlock.vue")
)

export default {
  name: 'QuestionsAndAnswers',
  components: {QuestionsAndAnswersBlock},
  props: {
    page: null
  },
}
</script>
