import ApiService from '@/services/api.service';
import store from '@/store'

const errorHandler = (error) => {
  if (error.response?.status === 401 ) {
    store.dispatch('auth/refresh_token');
  } else {
    return error;
  }
}

const initialState =
  {
    isAdmin: localStorage.getItem('user.isAdmin') ?? false,
    name: localStorage.getItem('user.name') ?? '',
    email: localStorage.getItem('user.email') ?? '',
    phone: localStorage.getItem('user.phone') ?? '',
  };

export const api = {
  namespaced: true,
  state: initialState,
  actions: {
    change_password({ commit }, data) {
      return ApiService.change_password(data).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    update_user({ commit }, data) {
      return ApiService.update_user(data).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    suggest_product({ commit }, data) {
      return ApiService.suggest_product(data).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    search_product({ commit }, data) {
      return ApiService.search_product(data).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_client({ commit }) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_client().then(
        response => {
          commit('updateUserData', {
            data: response.data,
          });
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('restoreUserData');
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_clients({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_clients(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_client({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_client(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_client({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_client(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_product({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_product(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_products({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_products(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_product({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_product(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_product({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_product(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_product_price({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_product_price(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_product_images({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_images(query).then(
          response => {
              commit('loadingState/stopLoading', null, { root: true });
              return Promise.resolve(response.data);
          },
          error => {
              errorHandler(error);
              commit('loadingState/stopLoading', null, { root: true });
              commit('notifierMessagesState/insertMessage', error, { root: true });
              return Promise.reject(error);
          }
      );
    },
    delete_product_image({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_image(data).then(
          response => {
              commit('loadingState/stopLoading', null, { root: true });
              return Promise.resolve(response.data);
          },
          error => {
              errorHandler(error);
              commit('loadingState/stopLoading', null, { root: true });
              commit('notifierMessagesState/insertMessage', error, { root: true });
              return Promise.reject(error);
          }
      );
    },
    edit_product_image({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_image(data).then(
          response => {
              commit('loadingState/stopLoading', null, { root: true });
              return Promise.resolve(response.data);
          },
          error => {
              errorHandler(error);
              commit('loadingState/stopLoading', null, { root: true });
              commit('notifierMessagesState/insertMessage', error, { root: true });
              return Promise.reject(error);
          }
      );
    },
    get_product_category({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_product_category(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_product_category({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_product_category(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_product_category({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_product_category(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_product_type({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_product_type(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_product_type({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_product_type(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_product_type({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_product_type(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_product_rating({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_product_rating(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_product_rating({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_product_rating(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_product_rating({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_product_rating(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_product_property_value({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_product_property_value(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_product_property_value({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_product_property_value(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_product_property_value({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_product_property_value(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_product_property({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_product_property(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_product_property({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_product_property(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_product_property({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_product_property(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_product_context({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_product_context(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_product_context({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_product_context(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_category_context({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_category_context(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_category_context({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_category_context(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    send_feedback_message({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.send_feedback_message(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', response, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_feedback_messages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_feedback_messages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_block_list({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_block_list(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_block_lists({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_block_lists(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_block_list({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_block_list(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_block_list({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_block_list(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_sales_promoution_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_sales_promoution_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_sales_promoution_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_sales_promoution_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_sales_promoution_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_sales_promoution_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_sales_promoution_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_sales_promoution_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },

    get_product_catalog_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_product_catalog_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_product_catalog_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_product_catalog_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_product_catalog_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_product_catalog_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_product_catalog_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_product_catalog_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_service_catalog_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_service_catalog_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_service_catalog_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_service_catalog_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_service_catalog_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_service_catalog_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_service_catalog_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_service_catalog_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_new_offer_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_new_offer_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_new_offer_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_new_offer_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_new_offer_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_new_offer_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_new_offer_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_new_offer_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_hot_offer_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_hot_offer_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_hot_offer_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_hot_offer_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_hot_offer_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_hot_offer_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_hot_offer_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_hot_offer_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },

    get_custom_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_custom_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_custom_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_custom_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_custom_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_custom_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_custom_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_custom_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_call_to_action_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_call_to_action_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_call_to_action_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_call_to_action_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_call_to_action_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_call_to_action_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_call_to_action_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_call_to_action_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },



    get_stages_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_stages_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_stages_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_stages_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_stages_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_stages_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_stages_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_stages_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },

    get_facts_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_facts_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_facts_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_facts_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_facts_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_facts_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_facts_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_facts_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },

    get_questions_and_answers_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_questions_and_answers_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_questions_and_answers_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_questions_and_answers_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_questions_and_answers_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_questions_and_answers_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_questions_and_answers_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_questions_and_answers_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },

    get_reviews_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_reviews_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_reviews_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_reviews_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_reviews_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_reviews_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_reviews_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_reviews_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },

    get_partners_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_partners_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_partners_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_partners_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_partners_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_partners_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_partners_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_partners_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },

    get_advantages_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_advantages_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_advantages_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_advantages_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_advantages_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_advantages_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_advantages_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_advantages_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },

    get_portfolio_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_portfolio_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_portfolio_pages({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_portfolio_pages(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_portfolio_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_portfolio_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_portfolio_page({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_portfolio_page(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },

    get_page_item({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_page_item(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_page_items({ commit }, query) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_page_items(query).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    delete_page_item({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.delete_page_item(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_page_item({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_page_item(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    edit_company_data({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.edit_company_data(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get_company_data({ commit }, data) {
      commit('loadingState/startLoading', null, { root: true });
      return ApiService.get_company_data(data).then(
        response => {
          commit('loadingState/stopLoading', null, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          errorHandler(error);
          commit('loadingState/stopLoading', null, { root: true });
          commit('notifierMessagesState/insertMessage', error, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    updateUserData(state, responseData) {
      state.isAdmin = responseData.data.isAdmin ?? false;
      state.name = responseData.data.name;
      state.email = responseData.data.email;
      state.phone = responseData.data.phone;
      localStorage.setItem('user.isAdmin', responseData.data.isAdmin === true ? true : false);
      localStorage.setItem('user.name', responseData.data.name);
      localStorage.setItem('user.email', responseData.data.email);
      localStorage.setItem('user.phone', responseData.data.phone);
    },
    restoreUserData(state) {
      state.isAdmin = false;
      state.name = '';
      state.email = '';
      state.phone = '';
      localStorage.removeItem('user.isAdmin');
      localStorage.removeItem('user.name');
      localStorage.removeItem('user.email');
      localStorage.removeItem('user.phone');
    }
  }
};
