<template>
  <Carousel
    class="corusel"
    :value="productTypes"
    :numVisible="3"
    :numScroll="1"
    :responsiveOptions="responsiveOptions"
    circular
    :autoplayInterval="5000">
    <template #empty>
      <div class="fix-height">
      </div>
    </template>
    <template #item="slotProps">
      <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
        <div class="flex align-items-center justify-content-center gap-2">
          <img class="hide-mobile w-6 shadow-2 rounded-3" :src="slotProps.data.image.link" :alt="slotProps.data.image.alt" />
          <div class="text-start mx-4">
            <h5 class="mb-1">{{ slotProps.data.value }}</h5>
            <p class="mb-1 card-description" style="height: 70px">{{ slotProps.data.description }}</p>
          </div>
        </div>
        <div class="flex align-items-center justify-content-center gap-2 mt-4">
          <button class="btn btn-outline-light-style" type="button" @click="routeToPageBoard(slotProps.data)"><span>Перейти к каталогу</span></button>
        </div>
      </div>
    </template>
  </Carousel>
</template>

<script>
import Carousel from 'primevue/carousel';
import Button from 'primevue/button';

import ProductType from "@/models/productType";
import ProductImage from "@/models/productImage";

export default {
  name: 'ProductCategories',
  data() {
    return {
      productTypes: [],
      responsiveOptions: [
        {
          breakpoint: '1400px',
          numVisible: 2,
          numScroll: 1
        },
        {
          breakpoint: '1199px',
          numVisible: 3,
          numScroll: 1
        },
        {
          breakpoint: '767px',
          numVisible: 2,
          numScroll: 1
        },
        {
          breakpoint: '575px',
          numVisible: 1,
          numScroll: 1
        }
      ]
    };
  },
  components: {
    Carousel,
    Button,
  },
  mounted() {
    this.getProductType();
  },
  methods: {
    routeToPageBoard(data) {
      this.$router.push(
        {
          path: 'product-price',
          query: {
            type: data.id,
            rangePrice: [0, 100000],
          }
        }
      )
    },
    getProductType() {
      this.$store.dispatch('api/get_product_type', {}).then(
        response => {
          this.totalRecordsLength = response['hydra:totalItems'];
          this.productTypes = response['hydra:member'].map(productType => {
            return new ProductType(
              productType.id,
              productType.value,
              productType.description,
              productType?.image ?? new ProductImage(
                null,
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                null
              ),
            )});
        },
        error => {
          // console.log(error.response);
        }
      );
    },
  }
}
</script>


<style lang="scss">
.corusel {
  .p-carousel-item div {
    background-color: var(--main-dark-color);
  }
  .fix-height {
    height: 350px;
  }
  &.p-carousel.p-component.p-carousel-horizontal {
    color: var(--main-light-text-color);
  }
  & img {
    height: 80px;
    width: 80px;
  }
  & a {
    color: var(--main-action-color);
  }
}
</style>
