export default class ProductCategoryTree {
  constructor(
    id ,
    title = '',
    parent = null
  ) {
    this.id = id;
    this.title = title;
    this.parent = parent;
  }
}
