<template>
  <AdvantagesBlock :page="page"/>
</template>

<script>
import {defineAsyncComponent} from "vue";

const AdvantagesBlock  = defineAsyncComponent(() =>
  import("@/components/block-items/AdvantagesBlock.vue")
)

export default {
  name: 'Advantages',
  components: {AdvantagesBlock},
  props: {
    page: null
  },
}
</script>
